import { Grid, Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/Layout";
import companies from "../img/finals-companies.png";
import keynote from "../img/keynote.png";
import panel from "../img/panel.png";
import day2 from "../img/day2.jpg";
import judge1 from "../img/finals/judge1.png";
import judge2 from "../img/finals/judge2.jpg";
import judge3 from "../img/finals/judge3.jpg";
import judge4 from "../img/finals/judge4.png";
import judge5 from "../img/finals/judge5.jpg";
import { NameCard } from "../pages/Team";

const team = [
  [judge1, "Prateek Sharma", "Sequoia India, VP"],
  [judge2, "Anna Lee Skates", "a16z, Partner"],
  [judge3, "Stephen Chambers", "IndieBio, Partner"],
  [judge4, "Navi Singh", "Antler, Partner"],
  [judge5, "Steve Zieja", "Edison Partners, Principal"],
];

export default function Finals(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              position: "relative",
              marginTop: "12rem",
              paddingBottom: "8rem",
              width: "100%",
            }}
            alignItems="center"
            spacing={4}
          >
            <Stack
              sx={{
                ...theme.font.h0,
                color: "white",
                py: 2,
                borderBottom: "1px solid white",
                width: "100%",
                textAlign: "center",
              }}
            >
              TigerLaunch Finals 2023
              <Stack
                sx={{
                  ...theme.font.h4,
                  color: "orange",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <span style={{
                    color: theme.mediumOrange,
                }}>April 1st @ 10:10 - 11:00 AM</span>
                Princeton Neuroscience Institute (PNI) A32
              </Stack>
            </Stack>

            <Stack
              sx={{
                ...theme.font.h4,
                lineHeight: "135%",
                color: "white",
                py: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Tune in on April 1st for our Global Finals, where we will award
              $45,000 in non-dilutive cash prizes to the most promising early
              stage student founders from around the world. These top 12 startup
              teams were selected from 6 qualifying regional pitches hosted in
              NYC, Austin, Los Angeles, Paris, London, and Singapore.
            </Stack>
            <Stack
              sx={{
                ...theme.font.h2,
                color: "white",
                py: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Schedule
            </Stack>
          <Stack>
          <Stack sx={{
            width: "max(50vh, 40rem)",
            p: 4,
            backgroundColor: theme.blackColor,
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            borderTopWidth: 4,
            borderTopColor: theme.mediumDarkOrange,
            borderTopStyle: "solid",
          }} spacing={4}>
            <ScheduleTime left={"9:00 - 10:00 AM"} right={"Breakfast"} />
            <ScheduleTime left={"10:00 - 10:10 AM"} right={"Welcome Speech"} />
            <ScheduleTime left={"10:10 - 11:00 AM"} right={"Gen Z VC / Founder Panel"} />
            <ScheduleTime left={"11:00 - 12:00 PM"} right={"Pitches, Round 1"} />
            <ScheduleTime left={"12:00 - 1:00 PM"} right={"Lunch & Open Networking"} />
            <ScheduleTime left={"1:00 - 2:00 PM"} right={"Pitches, Round 2"} />
            <ScheduleTime left={"2:00 - 3:00 PM"} right={"Keynote Speech"} />
            <ScheduleTime left={"3:00 - 3:10 PM"} right={"Awards Ceremony"} />
          </Stack>
          </Stack>
            <Stack
              sx={{
                ...theme.font.h2,
                color: "white",
                py: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Companies
            </Stack>
            <img
              src={companies}
              style={{
                width: "60%",
                height: "auto",
                objectFit: "cover",
                borderTop: `4px solid ${theme.orange}`,
              }}
            />
            <Stack
              sx={{
                ...theme.font.h3,
                color: "white",
                py: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Gen Z VCs & Founders Panel
            </Stack>
            <img
              src={panel}
              style={{
                width: "60%",
                height: "auto",
                objectFit: "cover",
                borderTop: `4px solid ${theme.orange}`,
              }}
            />
            <Stack
              sx={{
                ...theme.font.h2,
                color: "white",
                py: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Keynote Speaker
            </Stack>
            <img
              src={keynote}
              style={{
                width: "60%",
                height: "auto",
                objectFit: "cover",
                borderTop: `4px solid ${theme.orange}`,
              }}
            />
            <Stack
              sx={{
                ...theme.font.h2,
                color: "white",
                pt: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Our Amazing Judges!
            </Stack>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              justifyContent={"center"}
            >
              {team.map((person) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NameCard data={person} />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function ScheduleTime({left, right}) {
  const theme = useTheme();

  return (<Stack direction="row">
    <Stack sx={{
      width: "50%",
      fontSize: {
        md: "1.25rem",
        xs: "1rem",
      },
      fontFamily: "'Rubik', sans-serif",
      fontVariationSettings: "'wght' 600",
      color: theme.mediumDarkOrange,
    }}>
      {left}
    </Stack>
    <Stack sx={{
      width: "50%",
      fontSize: {
        md: "1.25rem",
        xs: "1rem",
      },
      fontFamily: "'Rubik', sans-serif",
      fontVariationSettings: "'wght' 400",
      color: "white",
    }}>
      {right}
    </Stack>
  </Stack>);
}