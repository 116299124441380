const ApplicationKeys = () => {
  return {
    startup_name: "",
    has_student: "",
    website: "",
    universities: "",
    applier_name: "",
    applier_email: "",
    applier_phone: "",
    region: "",
    airport: "",
    additional_names: "",
    additional_emails: "",
    description: "",
    categories: [],
    unique_team: "",
    competitors: "",
    market_size: "",
    traction: "",
    goals: "",
    plans: "",
    video: "",
    exposure: "",
    closest_regional: "",
  };
};

export default ApplicationKeys;
