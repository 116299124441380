import { db, auth, logOut, provider } from "./firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { Box, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { signInWithRedirect } from "firebase/auth";
import BigButton from "./generic/BigButton";

function ProtectedRoute(props) {
  //loggedinOnly, element, redirect, judgeAccess
  const authRef = collection(db, "user-emails");

  let [authorized, setAuthorized] = useState(!props.loggedinOnly);
  let [isJudge, setIsJudge] = useState(false);

  const checkEmail = async (loggedInEmail) => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(authRef, where("email", "==", loggedInEmail))
    );

    let email = snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
      };
    });
    console.log("EMAILS");
    console.log(email);
    if (
      email.length > 0 &&
      (email[0].TL || (email[0]?.judge && props.judgeAccess))
    ) {
      setAuthorized(true);
    } else {
      console.log("here");
      setAuthorized(false);
      if (email[0]?.judge) {
        navigate("/judge");
      }
    }
  };

  useEffect(() => {}, []);

  const [user, loading, error] = useAuthState(auth);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user && !props.loggedinOnly) {
      return navigate(props.redirect);
    }
    if (!user && props.loggedinOnly) return navigate(props.redirect);
    if (user) {
      checkEmail(user.email);
    }
  }, [user, loading]);

  if (
    loading ||
    (user && !props.loggedinOnly) ||
    (!user && props.loggedinOnly)
  ) {
    return <></>;
  }

  console.log(authorized);
  if (!authorized) {
    return (
      <Stack
        sx={{
          ...theme.font.h2,
          height: "100vh",
          textAlign: "center",
          fontFamily: "'Rubik'",
          ...theme.darkBg,
        }}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Stack
          sx={{
            ...theme.light,
          }}
        >
          Loading... you may not be authorized to view this page.
        </Stack>
        <BigButton
          sx={{
            ...theme.font.h4,
          }}
          text="Logout"
          onClick={() => navigate("/logout")}
        />
      </Stack>
    );
  }

  return props.element;
}

export default ProtectedRoute;
