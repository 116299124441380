import React, { useState, useEffect } from "react";
import { db, auth, provider } from "../firebase.js";
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import {
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";

import styles from "./Sorting.module.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import {
  EmojiEvents,
  FlightTakeoff,
  LocationOn,
  Mail,
  Person,
  Phone,
  StarRate,
} from "@mui/icons-material";
import Logo from "../generic/Logo";
import { InfoSection, SideCard, SortButton } from "./Sorting";
import { motion } from "framer-motion";
import BigButton from "../generic/BigButton";
import { signInWithRedirect } from "firebase/auth";

function Judging() {
  const appsRef = collection(db, "apps");
  const ratingsRef = collection(db, "ratings");
  const authRef = collection(db, "user-emails");
  const { width, height } = useWindowSize();

  let [application, setApplication] = useState(null);
  let [userApplications, setUserApplications] = useState([]);
  let [scores, setScores] = useState([...Array(5)]); // amount of questions
  let [comments, setComments] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState(null);
  const [judgedCount, setJudgedCount] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const getRatings = async (company_id, judge_email) => {
    let snapshot = await getDocs(
      query(
        ratingsRef,
        where("judge", "==", judge_email),
        where("application", "==", company_id)
      )
    );
    let ratings = snapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    if (ratings.length > 0) {
      return ratings[0];
    } else {
      return null;
    }
  };

  const getCompany = async (company) => {
    if (company == null) {
      setApplication(null);
      return;
    }
    let company_doc = await getDoc(doc(db, "apps", company.id));

    let rating = await getRatings(company.id, userInfo.email);
    if (rating != null) {
      setScores(rating.scores);
      setComments(rating.comments);
    } else {
      setScores([...new Array(5)]);
      setComments("");
    }

    if (company_doc) {
      setApplication({
        ...company_doc.data(),
        id: company.id,
        categories: company_doc.data().categories.join(", "),
      });
    }
  };

  const pushRatings = async () => {
    let rawscore =
      0.15 * scores[0] +
      0.25 * (scores[1] + scores[2] + scores[3]) +
      0.1 * scores[4];

    let rating = await getRatings(application.id, userInfo.email);
    let hasRating = rating != null;

    if (hasRating) {
      updateDoc(doc(db, "ratings", rating.id), {
        application: application.id,
        judge: userInfo.email,
        scores: scores,
        rawscore: rawscore,
        comments: comments,
      });
    } else {
      addDoc(ratingsRef, {
        application: application.id,
        judge: userInfo.email,
        scores: scores,
        rawscore: rawscore,
        comments: comments,
      });
    }
    console.log(hasRating);
    updateDoc(doc(db, "user-emails", userInfo.id), {
      judged: !hasRating ? userInfo.judged + 1 : userInfo.judged,
      assignedApplications: userInfo.assignedApplications.map((app) => {
        if (app.id == application.id) {
          return { ...app, judged: true };
        } else {
          return app;
        }
      }),
    });
    getUser();
  };

  const getUser = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(authRef, where("email", "==", user.email))
    );

    let emails = snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
    if (emails.length > 0) {
      setUserInfo(emails[0]);
    }
  };

  const isNonNullArray = (array) => {
    for (let item of array) {
      if (!item) return false;
    }
    return true;
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userInfo == null || userInfo.assignedApplications == null) {
      return;
    }
    setUserApplications(userInfo.assignedApplications);

    let company = getUnjudged();
    setJudgedCount(
      userInfo.assignedApplications.filter((app) => app.judged == true).length
    );
    getCompany(company);
  }, [userInfo]);

  const getUnjudged = () => {
    if (userInfo == null || userInfo.assignedApplications == null) {
      return null;
    }
    let unjudged = userInfo.assignedApplications.filter(
      (app) => app.judged == false
    );
    if (unjudged.length == 0) {
      return null;
    }
    return unjudged[0];
  };

  document.body.style.background = "whitesmoke";

  let bigBoxes = [
    ["description", "Description"],
    ["unique_team", "Unique Team Qualifications"],
    ["competitors", "Main Competitors"],
    ["market_size", "Market Size"],
    ["traction", "Traction"],
    ["goals", "Future Goals"],
  ];
  let littleBoxes = [
    ["universities", "Universities"],
    ["additional_emails", "Team Emails"],
    ["categories", "Industries"],
    ["exposure", "How they heard about TigerLaunch"],
  ];

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  let videoLink = null;
  if (application != null) {
    let link = application.video;
    if (link.includes("youtube") || link.includes("youtu.be")) {
      let id = getId(link);
      videoLink = "https://www.youtube.com/embed/" + id;
    }
  }

  let ratings = [1, 2, 3, 4, 5];

  let questions = [
    "How real is the problem they are trying to solve?",
    "How disruptive/differentiated is the company's idea and solution?",
    "How uniquely qualified is the team to execute their vision?",
    "To what extent has the team achieved product market fit?",
    "Based on their track record and future outlook, how confident are you in their customer acquisition strategy?",
  ];

  const [sidepanel, setSidepanel] = useState(false);
  // console.log(application);
  return (
    <Stack
      sx={{
        ...theme.darkBg,
        maxHeight: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
      alignItems="center"
    >
      {judgedCount < userInfo?.toJudge ? (
        <Stack
          sx={{
            ...theme.container,
            color: "white",
            position: "relative",
            height: "100%",
            borderLeft: "1px solid white",
          }}
        >
          <Stack
            component={motion.div}
            sx={{
              position: "fixed",
              width: "26rem",
              top: 0,
            }}
            animate={{
              left: sidepanel ? "0" : -24 * 16,
            }}
            transition={{}}
            direction="row"
            onMouseLeave={() => setSidepanel(false)}
          >
            <Stack
              sx={{
                width: "100%",
                py: 2,
                pl: 4,
                backgroundColor: theme.blackColor,
              }}
              spacing={1}
            >
              {userApplications.length > 0 &&
                userApplications.map((app) => (
                  <Stack
                    component={motion.div}
                    onClick={() => getCompany(app)}
                    whileHover={{
                      filter: "brightness(0.8)",
                    }}
                    sx={{
                      ...theme.font.h5,
                      cursor: "pointer",
                    }}
                    style={{
                      color: app.judged ? "#19d11c" : "white",
                    }}
                    key={app.startup_name}
                  >
                    {app.startup_name}
                  </Stack>
                ))}
            </Stack>
            <Stack
              sx={{
                width: "2rem",
                height: "8rem",
                backgroundColor: theme.orange,
              }}
              onMouseOver={() => setSidepanel(true)}
            />
          </Stack>
          <Stack
            sx={{
              height: "100vh",
              width: "100%",
              px: 2,
            }}
          >
            <Grid
              container
              columnSpacing={1}
              sx={{
                height: "100%",
              }}
            >
              <Grid
                item
                xs={8.5}
                sx={{
                  overflowY: "scroll",
                  height: "100%",
                  pr: 1,
                  pb: 10,
                }}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    mb: 1,
                    pt: 2,
                  }}
                >
                  {application?.startup_name ?? "No Company Name"}
                </Stack>
                <Stack
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    mb: 2,
                  }}
                >
                  <Box
                    component="a"
                    sx={{
                      ...theme.font.h5,
                      color: "white",
                    }}
                    href={
                      (application?.website.includes("http")
                        ? ""
                        : "https://") + application?.website
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {application?.website}
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    ...theme.font.h2,
                  }}
                  mt={6}
                >
                  Macros
                </Stack>
                <Stack spacing={6} mt={2}>
                  {application &&
                    bigBoxes.map((v) => (
                      <InfoSection key={v[0]} title={v[1]}>
                        {application[v[0]]}
                      </InfoSection>
                    ))}
                </Stack>
                <Stack
                  sx={{
                    ...theme.font.h2,
                  }}
                  mt={6}
                >
                  Additional Info
                </Stack>
                <Stack spacing={6} mt={2}>
                  {application &&
                    littleBoxes.map((v) => (
                      <InfoSection key={v[0]} title={v[1]}>
                        {application[v[0]]}
                      </InfoSection>
                    ))}
                </Stack>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  pb: 10,
                }}
              >
                <Stack
                  spacing={1}
                  sx={{
                    position: "sticky",
                    right: 0,
                    top: 0,
                    pt: 1,
                  }}
                >
                  <SideCard
                    header="regional"
                    text={application?.closest_regional}
                    icon={<EmojiEvents />}
                  />
                  <SideCard
                    header="location"
                    text={application?.region}
                    icon={<LocationOn />}
                  />
                  <SideCard
                    header="airport"
                    text={application?.airport}
                    icon={<FlightTakeoff />}
                  />
                  <SideCard
                    header="contact"
                    text={application?.applier_name}
                    icon={<Person />}
                  />
                  <SideCard
                    header="email"
                    text={application?.applier_email}
                    icon={<Mail />}
                  />
                  <SideCard
                    header="phone"
                    text={application?.applier_phone}
                    icon={<Phone />}
                  />
                  {videoLink != null && (
                    <iframe
                      src={videoLink}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        aspectRatio: "16 / 9",
                      }}
                    />
                  )}
                  <BigButton
                    sx={{
                      width: "100%",
                      textAlign: "left",
                    }}
                    text="To Dashboard"
                    onClick={() => navigate("/dashboard")}
                  />
                  <Stack
                    sx={{
                      height: "1px",
                      borderBottom: "1px solid white",
                      pt: 4,
                    }}
                  />
                  <Stack
                    sx={{
                      ...theme.font.h3,
                      px: 1,
                      pt: 2,
                    }}
                  >
                    Judging
                  </Stack>
                  <Stack spacing={4}>
                    {questions.map((q, i) => (
                      <JudgeQuestion
                        key={q}
                        question={q}
                        index={i}
                        ratings={ratings}
                        scores={scores}
                        setScores={setScores}
                      />
                    ))}
                  </Stack>
                  <Stack
                    spacing={2}
                    sx={{
                      pt: 4,
                      px: 1,
                    }}
                  >
                    <Stack
                      sx={{
                        ...theme.font.h5,
                      }}
                    >
                      Comments
                    </Stack>
                    <textarea
                      onChange={(e) => {
                        setComments(e.target.value);
                      }}
                      value={comments}
                      cols="30"
                      rows="10"
                      style={{
                        border: "white 1px solid",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            sx={{
              position: "sticky",
              p: 1,
              top: "auto",
              bottom: 0,
              borderTop: "1px solid white",
              ...theme.darkBg,
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              direction="row"
              sx={{
                height: "3rem",
              }}
              alignItems="center"
              spacing={2}
            >
              <Logo />
              <Stack
                sx={{
                  ...theme.font.h5,
                  fontFamily: "Rubik",
                  fontVariationSettings: "'wght' 900",
                }}
              >
                Judging
              </Stack>
              <Stack
                sx={{
                  ...theme.font.h5,
                }}
              >
                ({judgedCount} / {userInfo?.toJudge} judged)
              </Stack>
            </Stack>
            <Stack direction="row">
              {/* <SortButton
                text="Skip"
                onClick={() => getCompany(getUnjudged())}
              /> */}
              <SortButton
                text="Submit"
                color={isNonNullArray(scores) ? "#77dd77" : null}
                onClick={isNonNullArray(scores) ? pushRatings : () => {}}
              />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            height: "100vh",
          }}
          alignItems="center"
        >
          <Confetti width={width} height={height} />
          <Stack
            sx={{
              ...theme.container,
              height: "100vh",
              borderLeft: "1px solid white",
            }}
          >
            <Stack
              sx={{
                ...theme.font.h1,
                ...theme.light,
                fontFamily: "'Rubik'",
                flexShrink: 1,
                py: 8,
                px: 4,
              }}
              direction="row"
              spacing={{
                md: 4,
                xs: 2,
              }}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Stack
                sx={{
                  height: "8rem",
                }}
              >
                <Logo />
              </Stack>
              <Stack
                sx={{
                  height: "100%",
                  py: 2,
                  overflow: "hidden",
                }}
                justifyContent="space-between"
                spacing={1}
              >
                <Stack>Thank you for judging!</Stack>
                <Stack
                  sx={{
                    ...theme.font.h4,
                    ...theme.light,
                  }}
                >
                  TigerLaunch wouldn't be possible without you.
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{
                px: 4,
              }}
            >
              <BigButton
                sx={{
                  ...theme.font.h4,
                  px: 4,
                }}
                text="Logout"
                onClick={() => navigate("/logout")}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function JudgeQuestion(props) {
  const { question, index, ratings, scores, setScores } = props;
  const theme = useTheme();

  const calculateColor = (score) => {
    if (score === 5) return theme.orange;
    if (score === 4) return theme.mediumDarkOrange;
    if (score === 3) return theme.mediumOrange;
    if (score === 2) return theme.mediumLightOrange;
    if (score === 1) return theme.lightOrange;
  };

  return (
    <Stack spacing={0.5}>
      <Stack
        sx={{
          ...theme.font.h5,
          p: 1,
        }}
      >
        {question}
      </Stack>
      <Stack
        sx={{
          px: 1,
        }}
      >
        <Grid
          container
          sx={{
            overflow: "hidden",
            borderRadius: "1rem",
          }}
        >
          {ratings.map((r, r2) => (
            <Grid
              item
              xs={2.4}
              key={index + r2 * 10}
              component={motion.div}
              sx={{
                textAlign: "center",
              }}
            >
              <Stack
                component={motion.div}
                animate={{
                  background:
                    scores[index] >= r
                      ? calculateColor(scores[index])
                      : "rgba(255, 255, 255, 0.16)",
                }}
                sx={{
                  py: 0.125,
                }}
              >
                <input
                  type={"radio"}
                  value={r}
                  checked={scores[index] === r}
                  style={{
                    display: "none",
                  }}
                />
                <Stack
                  sx={{
                    ...theme.font.smallTitle,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  onClick={() => {
                    setScores((prev) => {
                      let n = prev;
                      n[index] = r;
                      return [...n];
                    });
                  }}
                >
                  {r}
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

/*
<div className="mb-3">
      {ratings.map((i, index2) => {
        return (
            <Form.Check
                inline
                label={i}
                value={i}
                key={index + index2 * 10}
                name={"q" + index}
                checked={scores[index] === i}
                onChange={() => {
                  console.log(i);
                  setScores((prev) => {
                    let n = prev;
                    n[index] = i;
                    console.log(n);
                    return [...n];
                  });
                }}
                type="radio"
            />
        );
      })}
    </div>
 */

/*
<div className={styles.container}>
      <center>
        <h3 className="mt-2 mb-3">Sorting</h3>
      </center>
      {application != null &&
        userInfo != null &&
        userInfo.judged < userInfo.toJudge && (
          <>
            <center>
              <h1 className="mb-1">
                {application.startup_name}
                <small
                  className={
                    "ms-2 " +
                    (application.has_student === "true"
                      ? "text-success"
                      : "text-danger")
                  }
                >
                  (
                  {(application.has_student === "true" && "valid") || "invalid"}
                  )
                </small>
              </h1>
              <h5 className="mb-3">
                <a href={application.url}>{application.url}</a> |{" "}
                {application.closest_regional}
              </h5>
              <h4>
                {userInfo.judged}/{userInfo.toJudge} Applications Judged
              </h4>
              <h4 className="mt-3">Long Answer Questions</h4>
            </center>
            <Row>
              {bigBoxes.map((key) => {
                return (
                  <Col xs={6}>
                    <div className={styles.shadow}>
                      <center>
                        <h5>{key[1]}</h5>
                      </center>
                      <p className="">{application[key[0]]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>

            <center>
              <h4 className="mt-3">Video</h4>{" "}
              <h6 className="mb-3">
                <a href={application.video}>{application.video}</a>
              </h6>
              <iframe
                className={styles.video}
                width="560"
                height="315"
                src={videoLink}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              <h4>Additional Information</h4>
            </center>
            <Row>
              {littleBoxes.map((key) => {
                return (
                  <Col>
                    <div className={styles.shadow}>
                      <center>
                        <h5>{key[1]}</h5>
                      </center>
                      <p className="">{application[key[0]]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <center>
              <h4>Contact Information</h4>
            </center>
            <Row>
              {contactBoxes.map((key) => {
                return (
                  <Col>
                    <div className={styles.shadow}>
                      <center>
                        <h5>{key[1]}</h5>
                      </center>
                      <p className="">{application[key[0]]}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <center>
              {questions.map((question, index) => {
                return (
                  <Row key={index}>
                    <h2>{question}</h2>
                    <div className="mb-3">
                      {ratings.map((i, index2) => {
                        return (
                          <Form.Check
                            inline
                            label={i}
                            value={i}
                            key={index + index2 * 10}
                            name={"q" + index}
                            checked={scores[index] == i}
                            onChange={() => {
                              console.log(i);
                              setScores((prev) => {
                                let n = prev;
                                n[index] = i;
                                console.log(n);
                                return [...n];
                              });
                            }}
                            type="radio"
                          />
                        );
                      })}
                    </div>
                  </Row>
                );
              })}
              <Button
                onClick={pushRatings}
                disabled={scores.reduce(
                  (prev, curr) => curr == undefined || prev,
                  false
                )}
              >
                Submit
              </Button>
            </center>
          </>
        )}
      {userInfo != null && userInfo.judged >= userInfo.toJudge && (
        <>
          <center>
            <h1>You have judged all the applications you need to judge.</h1>
          </center>
        </>
      )}
      <br />
      <br />
    </div>
  */

export default Judging;
