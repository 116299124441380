import React, { useState, useEffect, useCallback } from "react";
import { db, auth, provider } from "../firebase.js";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import {
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import Logo from "../generic/Logo";
import BigButton from "../generic/BigButton";
import { signInWithRedirect } from "firebase/auth";
import { Check, Close, Info } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import { AnimateSharedLayout, motion } from "framer-motion";
import ApplicationKeys from "./ApplicationKeys.js";

function Assign() {
  const appsRef = collection(db, "apps");
  const authRef = collection(db, "user-emails");
  const auth = getAuth();
  const theme = useTheme();

  let [applications, setApplications] = useState([]);
  let [customApps, setCustomApps] = useState([]);
  let [addedApplications, setAddedApplications] = useState([]);
  let [judgeEmail, setJudgeEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const getCompanies = async () => {
    let snapshot = await getDocs(query(appsRef, where("score", "==", 1)));
    let data = [];
    snapshot.docs.map((doc) => {
      data.push({ ...doc.data(), id: doc.id, selected: false });
    });
    setApplications(data);
    setCustomApps(data);
  };

  const createJudge = async () => {
    let judgeObj = {
      email: judgeEmail.toLowerCase(),
      judge: true,
      judged: 0,
      toJudge: addedApplications.length,
      assignedApplications: addedApplications.map((app) => {
        return {
          id: app.id,
          startup_name: app.startup_name,
          judged: false,
        };
      }),
    };
    console.log(judgeObj);
    addDoc(authRef, judgeObj);

    window.alert(
      `Judge submitted!\nEmail: ${judgeEmail}\n# of apps: ${addedApplications.length}`
    );

    setAddedApplications([]);
    setJudgeEmail("");
    // addDoc(mailRef, {
    //   to: [inputs.email],
    //   message: {
    //     subject: "Thank you for submitting your application to TigerLaunch!",
    //     text: "Your TigerLaunch application has been successfully submitted! We will be in touch by early December with the status of your application. If you have any questions in the meantime, please contact jp6294@princeton.edu and hnabeshima@princeton.edu.",
    //   },
    // });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const search = (arr, term) => {
    return arr.filter((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (
          (typeof value == "string" &&
            value.toLocaleLowerCase().includes(term.toLocaleLowerCase())) ||
          (Array.isArray(value) &&
            value
              .toString()
              .toLocaleLowerCase()
              .includes(term.toLocaleLowerCase()))
        ) {
          return true;
        }
      }
      return false;
    });
  };

  const [show, setShow] = useState(-1);

  const initialInputs = ApplicationKeys();

  const tableHeadings = Object.keys(initialInputs);

  const handleClose = () => setShow(-1);

  const handleShow = useCallback(
    (app) => {
      setShow(app);
    },
    [show]
  );

  const addApp = useCallback(
    (app) => {
      setAddedApplications((old) => {
        if (
          old.filter((a) => a.startup_name === app.startup_name).length === 0
        ) {
          return [...old, app];
        } else return old;
      });
    },
    [addedApplications]
  );

  const removeApp = useCallback(
    (app) => {
      setAddedApplications((old) => {
        let data = [...old];
        if (
          data.filter((a) => a.startup_name === app.startup_name).length > 0
        ) {
          data.splice(data.indexOf(app), 1);
        }
        return data;
      });
    },
    [addedApplications]
  );

  const enterSearch = () => {
    setCustomApps(search(applications, searchTerm));
  };
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        ...theme.darkBg,
      }}
      alignItems="center"
    >
      <Modal show={show !== -1} onHide={handleClose} scrollable={true}>
        {show !== -1 && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{show.startup_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {show !== -1 &&
                tableHeadings.map((key) => {
                  return (
                    <div key={key}>
                      <p>{key}</p>
                      <p className="ps-3 mb-4">
                        <b>{show[key]}</b>
                      </p>
                    </div>
                  );
                })}
            </Modal.Body>
            <Modal.Footer />
          </>
        )}
      </Modal>
      <Stack
        sx={{
          ...theme.container,
          minHeight: "100vh",
          borderLeft: "1px solid white",
        }}
      >
        <Stack
          sx={{
            ...theme.font.h1,
            ...theme.light,
            fontFamily: "'Rubik'",
            flexShrink: 1,
            py: 8,
            px: 4,
          }}
          direction="row"
          spacing={{
            md: 4,
            xs: 2,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              height: "8rem",
            }}
          >
            <Logo />
          </Stack>
          <Stack
            sx={{
              height: "100%",
              py: 2,
            }}
            justifyContent="space-between"
            spacing={1}
          >
            <Stack>Assign Applications</Stack>
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Link a judge with their own pool of applications.
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            px: 4,
          }}
          spacing={2}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Email:
            </Stack>
            <Box
              component="input"
              type="email"
              value={judgeEmail}
              onChange={(e) => {
                setJudgeEmail(e.target.value);
              }}
              sx={{
                ...theme.subQuestion,
              }}
            />
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{
              pb: 1,
            }}
          >
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Search:
            </Stack>
            <Box
              component="input"
              type="test"
              spellCheck={false}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") enterSearch();
              }}
              sx={{
                ...theme.subQuestion,
              }}
            />
          </Stack>
          <Stack
            sx={{
              pb: 1,
            }}
            spacing={1}
            direction="row"
            justifyContent="space-between"
          >
            <BigButton
              text={"Submit"}
              onClick={
                judgeEmail.length > 0 && addedApplications.length > 0
                  ? createJudge
                  : () => {}
              }
            />
            {/* <BigButton
              text={"Special Button for Tim"}
              onClick={() => {
                const judgeEmailsString =
                  "jill@capitalfactory.com,sanikabhave@capitalfactory.com,mrossi@ggvc.com,savannah.greene@workday.com,apierce@edisonpartners.com,ssingh@insightpartners.com,vchari@adamsstreetpartners.com,lburnham@ideafundpartners.com,nicole@harlem.capital,lenny@mithril.com,emily@root.vc,tjeanjacques@generalatlantic.com,adam@overlookedventures.com,morgan@m13.co,claire@boxgroup.com,sawadogo@alumni.princeton.edu,zcole@baincapital.com,sri@emersoncollective.com,ajain@nea.com ,ayushis@alumni.princeton.edu,ibrahim@dcm.com,azhang@ivp.com,tobias@primary.vc,edwin@corevc.com,aditnbhansali@gmail.com,arun@monkshill.com,lquirk@645ventures.com,ascantwel@gmail.com,benji@thirdprime.vc,tshen@hofcapital.com";
                const judgeEmails = judgeEmailsString.split(",");
                judgeEmails.forEach((email) => {
                  createUserWithEmailAndPassword(auth, email, ".....")
                    .then((userCredential) => {
                      console.log("created successfully: " + email);
                    })
                    .catch((error) => {
                      const errorCode = error.code;
                      const errorMessage = error.message;
                      console.log(errorMessage);
                    });
                });
              }}
            /> */}
            <BigButton
              text={"To Dashboard"}
              onClick={() => navigate("/dashboard")}
            />
          </Stack>
          <AnimateSharedLayout>
            <Stack
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              }}
            >
              <BigGrid
                apps={addedApplications}
                info={handleShow}
                click={removeApp}
              />
            </Stack>
            <BigGrid
              apps={customApps}
              added={addedApplications}
              info={handleShow}
              click={addApp}
              check
            />
          </AnimateSharedLayout>
        </Stack>
      </Stack>
      <br />
      <br />
    </Stack>
  );
}

const BigGrid = React.memo((props) => {
  const { apps, added, check, info, click } = props;

  return (
    <Grid container>
      {apps
        ?.filter((a) => {
          if (added) {
            for (let app of added) {
              if (app.startup_name === a.startup_name) return false;
            }
          }
          return true;
        })
        .map((app, index) => (
          <GridInfo
            key={app.startup_name + index}
            check={check}
            app={app}
            index={index}
            info={info}
            click={click}
          />
        ))}
    </Grid>
  );
});

function GridInfo(props) {
  const { app, index, info, click, check } = props;
  const theme = useTheme();

  const onInfo = useCallback(() => {
    info(app);
  }, [app]);

  const onMove = useCallback(() => {
    click(app);
  }, [app]);

  return (
    <Grid
      component={motion.div}
      layout
      layoutId={app.startup_name + index}
      key={app.startup_name + index}
      item
      xl={3}
      lg={4}
      xs={6}
      sx={{
        ...theme.light,
        height: "100%",
        p: 0.5,
      }}
    >
      <Stack
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.16)",
        }}
        spacing={0.5}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderTop: "2px solid white",
            pt: 2,
            px: 2,
          }}
        >
          <Stack
            sx={{
              ...theme.font.h4,
            }}
          >
            {app.startup_name}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Info
              onClick={onInfo}
              sx={{
                transform: "scale(1.35)",
                cursor: "pointer",
              }}
            />
            {check ? (
              <Check
                onClick={onMove}
                sx={{
                  transform: "scale(1.35)",
                  cursor: "pointer",
                }}
              />
            ) : (
              <Close
                onClick={onMove}
                sx={{
                  transform: "scale(1.35)",
                  cursor: "pointer",
                }}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{
            ...theme.sortButton,
            px: 2,
          }}
        >
          {app.region}
        </Stack>
        <Stack
          direction="row"
          sx={{
            pt: 0.5,
            px: 2,
            flexWrap: "wrap",
          }}
        >
          {app.categories.map((v) => (
            <Box
              key={v}
              sx={{
                ...theme.badge,
                backgroundColor: theme.mediumOrange,
                color: "black",
                width: "fit-content",
                mb: 1,
                mr: 1,
              }}
            >
              {v}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
}

export default Assign;

/*
<center>
      <h1>Assign Page</h1>
      <Container>
        <h3>Email</h3>
        <input
          type="email"
          value={judgeEmail}
          onChange={(e) => {
            setJudgeEmail(e.target.value);
          }}
          style={{ border: "solid 1px red", color: "black" }}
        />
      </Container>
      <Container>
        <h3>Search Bar</h3>
        <input
          type="test"
          style={{ border: "solid 1px red", color: "black" }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </Container>

      {search(applications, searchTerm).map((app, index) => {
        return (
          <div key={index}>
            <h3>
              {app.company_name}, ({app.categories}),{" "}
              {app.selected ? "true" : "false"}
            </h3>
            <input
              type={"checkbox"}
              checked={addedApplications.includes(app)}
              onChange={() => {
                setAddedApplications((apps) => {
                  if (apps.includes(app)) {
                    return apps.filter((a) => a != app);
                  }
                  return [...apps, app];
                });
              }}
            />
          </div>
        );
      })}
      <Button
        disabled={judgeEmail == "" || addedApplications.length == 0}
        onClick={createJudge}
      >
        Create Judge
      </Button>
    </center>
 */
