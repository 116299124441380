import { Grid, Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/Layout";
import Sophia from "../img/team/sophia.jpg";
import Richard from "../img/team/richard.jpg";
import Christine from "../img/team/christine.jpg";
import Brian from "../img/team/brian.jpg";
import William from "../img/team/william.jpg";
import Tim from "../img/team/tim.jpg";
import Illia from "../img/team/illia.jpg";
// partnerships
import Alexei from "../img/team/alexei.jpg";
import Jack1 from "../img/team/jack.jpg";
import Jack2 from "../img/team/jack2.jpg";
import Athan from "../img/team/athan.jpg";
import Eliana from "../img/team/eliana.jpg";
// logistics
import Haruka from "../img/team/haruka.jpg";
import Nathan from "../img/team/nathan.jpg";
import Sawooly from "../img/team/sawooly.jpg";
import Jeffery from "../img/team/jeffery.jpg";
// branding
import Jason from "../img/team/jason.jpg";
import Natalie from "../img/team/natalie.jpg";
import Alex from "../img/team/alex.jpg";
import Winsice from "../img/team/winsice.jpg";

import teamPhoto from "../img/team/team.jpg";
import Partnerships from "../img/team/partnerships.jpg";
import Logistics from "../img/team/logistics.jpg";
import Branding from "../img/team/branding.jpg";

const team = [
  [Sophia, "Sophia Shepherd", "Executive Director"],
  [Richard, "Richard Gomez", "Executive Director"],
  [Christine, "Christine Ao", "Branding Director"],
  [Brian, "Brian Zhou", "Partnerships Director"],
  [William, "William Zhang", "Treasurer"],
  [Illia, "Illia Filipacchi", "Logistics Director"],
  [Tim, "Tim Gubskiy", "Tech Director"],
  // [Skyla, "Skyla Qian", "Branding"],
  // [Kim, "Kim Tran", "Logistics"],
  // [Akshat, "Akshat Agarwal", "Logistics"],
  // [Helen, "Helen Zhao", "Branding"],
  // [Katie, "Katie Lee", "Logistics"],
  // [Eugene, "Eugene Choi", "Partnerships"],
  // [Chris, "Christian Lalin", "Partnerships"],
  // [Alex, "Alexander Kirk", "Branding"],
  // [Tyler, "Tyler Vu", "Branding"],
  // [Jason, "Jason Luo", "Logistics"],
  // [Allen, "Allen Shen", "Branding"],
  // [Jackie, "Jackie Liu", "Branding"],
  // [Brian, "Brian Zhou", "Partnerships"],
];

const partnerships = [
  [Alexei, "Alexei Korolev", "Partnerships"],
  [Jack1, "Jack Prewitt", "Partnerships"],
  [Jack2, "Jack Hasker", "Partnerships"],
  [Athan, "Athan Zhang", "Partnerships"],
  [Eliana, "Eliana Bane", "Partnerships"],
];

const logistics = [
  [Haruka, "Haruka Nabeshima", "Logistics"],
  [Nathan, "Nathan Nguyen", "Logistics"],
  [Sawooly, "Sawooly Li", "Logistics"],
  [Jeffery, "Jeffery Xu", "Logistics"],
];

const branding = [
  [Jason, "Jason Persaud", "Branding"],
  [Natalie, "Natalie Laszewski", "Branding"],
  [Alex, "Alex Kirk", "Branding"],
  [Winsice, "Winsice Ng", "Branding"],
];

export default function Team(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              position: "relative",
              marginTop: "12rem",
              paddingBottom: "8rem",
              width: "100%",
            }}
            alignItems="center"
            spacing={4}
          >
            <Stack
              sx={{
                ...theme.font.h0,
                color: "white",
                py: 2,
                borderBottom: "1px solid white",
                width: "100%",
                textAlign: "center",
              }}
            >
              Meet the Team
            </Stack>
              <Stack
                  sx={{
                      color: theme.orange,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                      py: 2,
                  }}
              >
                  <div
                      style={{
                          color: theme.orange,
                          fontWeight: "bold",
                          fontSize: "24px",
                      }}
                  >
                      Want to join our team for the 2024-2025 year?
                  </div>
                  <div
                      style={{
                          color: "white",
                          fontSize: "18px",
                      }}
                  >
                      Fill out this interest form to get information about our application cycle:{" "}
                      <a
                          href="https://tinyurl.com/tlinterest2024"
                          style={{
                              color: theme.orange,
                              textDecoration: "underline",
                          }}
                      >
                          tinyurl.com/tlinterest2024
                      </a>
                  </div>
              </Stack>
            <Grid container rowSpacing={2} justifyContent={"center"}>
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Grid item xs={12} md={12}>
                  {/* not centered wtf pls fix */}
                  <img
                    src={teamPhoto}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  paddingLeft: 0,
                }}
              >
                <NameCard data={team[0]} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  paddingLeft: 0,
                }}
              >
                <NameCard data={team[1]} />
              </Grid>
              {team.slice(2).map((person) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NameCard data={person} />
                  </Grid>
                );
              })}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Partnerships
                </Stack>
                <Grid item xs={12} md={12}>
                  {/* not centered wtf pls fix */}
                  <img
                    src={Partnerships}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {partnerships.map((person) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NameCard data={person} />
                  </Grid>
                );
              })}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Logistics
                </Stack>
                <Grid item xs={12} md={12}>
                  {/* not centered wtf pls fix */}
                  <img
                    src={Logistics}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {logistics.map((person) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NameCard data={person} />
                  </Grid>
                );
              })}
              <Stack
                sx={{
                  color: "white",
                  width: "100%",
                  p: 4,
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                }}
                spacing={2}
              >
                <Stack
                  sx={{
                    ...theme.font.h0,
                    color: "white",
                    py: 2,
                    borderBottom: "1px solid white",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Branding
                </Stack>
                <Grid item xs={12} md={12}>
                  {/* not centered wtf pls fix */}
                  <img
                    src={Branding}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderTop: `4px solid ${theme.orange}`,
                    }}
                  />
                </Grid>
              </Stack>
              {branding.map((person) => {
                return (
                  <Grid item xs={12} md={4}>
                    <NameCard data={person} />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function NameCard(props) {
  const { data } = props;
  const theme = useTheme();

  return (
    <Stack
      sx={{
        color: "white",
        width: "100%",
        p: 4,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      spacing={2}
    >
      <img
        src={data[0]}
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: "2/1.8",
          objectFit: "cover",
          borderTop: `4px solid ${theme.orange}`,
        }}
      />
      <Stack direction="column" spacing={1} justifyContent="center">
        <Stack
          sx={{
            ...theme.font.h3,
          }}
        >
          {data[1]}
        </Stack>
        <Stack
          sx={{
            ...theme.font.h5,
            color: theme.lightOrange,
          }}
        >
          {data[2]}
        </Stack>
      </Stack>
    </Stack>
  );
}
