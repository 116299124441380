import { Box, Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/Layout";
import Nassau from "../img/nassau.jpg";
import Perch from "../img/companies/perch.png";
import LineLeap from "../img/companies/lineleap.png";
import Meetingbird from "../img/companies/meetingbird.png";
import Groover from "../img/companies/groover.png";
import SwineTech from "../img/companies/swinetech.png";
import { motion } from "framer-motion";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import PartnersImg from "../img/partners.png";
import CompanyCard from "./CompanyCard";

const animations = {
  heroArrow: {
    inactive: {
      bottom: "-2rem",
      opacity: 0,
    },
    active: {
      bottom: "2rem",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0,
      },
    },
  },
  heroNames: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0,
        delayChildren: 0.25,
        staggerChildren: 0.1,
      },
    },
  },
  heroName: {
    inactive: {
      bottom: -64,
      opacity: 0,
    },
    active: {
      bottom: 0,
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
      },
    },
  },
};

const companies = [
  [
    "Perch",
    "Perch operates as an intermediary between banks and college students, enabling students to build their credit score without a credit card or loan. Through this, Perch aims to realign financial institutions with the college market.",
    Perch,
    ["TL 2020", "YC 2020"],
  ],
  [
    "LineLeap",
    'LineLeap is a mobile platform providing users with the ability to purchase "fast pass entry" at their favorite venues.',
    LineLeap,
    ["TL 2019", "YC 2019"],
  ],
  [
    "Groover",
    "Groover helps musicians and their representatives to send tunes directly to carefully selected blogs, record labels, and other music influencers of their choice.",
    Groover,
    ["TL 2019", "Seed $1.3M"],
  ],
  [
    "Meetingbird",
    "Meetingbird is an automated and modern scheduling platform that aims to homogenize scheduling among major web platforms like Slack, Gmail, and zoom. As of 2020, it has been acquired by Front.",
    Meetingbird,
    ["TL 2016", "YC 2017"],
  ],
  [
    "SwineTech",
    "SwineTech aims to revolutionize swine health within the pork industry by introducing health sensors that monitor piglet health, thereby reducing revenue loss.",
    SwineTech,
    ["TL 2016", "Seed $2.8M"],
  ],
];

export default function Companies(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              position: "relative",
              marginTop: "12rem",
              paddingBottom: "8rem",
              width: "100%",
            }}
            alignItems="center"
            spacing={4}
          >
            <Stack
              sx={{
                ...theme.font.h0,
                color: "white",
                py: 2,
                borderBottom: "1px solid white",
                width: "100%",
                textAlign: "center",
              }}
            >
              Companies
            </Stack>
            <Stack
              sx={{
                ...theme.font.h4,
                color: "white",
                pb: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Since our founding in 2014, our competitors have raised a
              cumulative $1.4B in venture funding, and have been accepted to
              YCombinator every year.
            </Stack>
            <Stack
              sx={{
                width: "100%",
              }}
              spacing={4}
            >
              {companies.map((v, i) => {
                return (
                  <CompanyCard
                    name={v[0]}
                    desc={v[1]}
                    imgUrl={v[2]}
                    tags={v[3]}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
