import React, { useState, useEffect } from "react";
import { db } from "../firebase.js";
import { Link, useNavigate } from "react-router-dom";
import {
  EmojiEvents,
  FlightTakeoff,
  LocationOn,
  Mail,
  Phone,
  StarRate,
  Person,
  VideoFile,
} from "@mui/icons-material";

import {
  collection,
  doc,
  getDocs,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import styles from "./Sorting.module.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import Logo from "../generic/Logo";
import BigButton from "../generic/BigButton";
import ApplicationKeys from "./ApplicationKeys.js";

const scoreText = ["No status", "Greenlit!", "Recheck needed.", "Rejected..."];

const scoreColor = ["#ffffff", "#ff6961", "#dded66", "#77dd77"];

function Sorting() {
  const appsRef = collection(db, "apps");
  const theme = useTheme();
  const navigate = useNavigate();

  let [application, setApplication] = useState(null);

  const getCompany = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(
        appsRef,
        where("score", "in", [0, 2]),
        // orderBy("score")
        orderBy("last_access")
      )
    );
    let tempApps = snapshot.docs
      .map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
          categories: doc.data().categories.join(", "),
        };
      })
      .sort((a, b) => a.score - b.score);
    if (tempApps.length > 0) {
      setApplication(tempApps[0]);
      updateDoc(doc(db, "apps", tempApps[0].id), {
        last_access: Timestamp.now(),
      });
      console.log(tempApps[0]);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const initialInputs = ApplicationKeys();

  const tableHeadings = Object.keys(initialInputs);

  document.body.style.background = "whitesmoke";

  const updateScore = (newScore) => {
    updateDoc(doc(db, "apps", application.id), {
      score: newScore,
    });
    getCompany();
  };

  let bigBoxes = [
    ["description", "Description"],
    ["unique_team", "Unique Team Qualifications"],
    ["competitors", "Main Competitors"],
    ["market_size", "Market Size"],
    ["traction", "Traction"],
    ["goals", "Future Goals"],
  ];
  let littleBoxes = [
    ["universities", "Universities"],
    ["additional_emails", "Team Emails"],
    ["categories", "Industries"],
    ["exposure", "How they heard about TigerLaunch"],
  ];

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  let videoLink = "";
  if (application != null) {
    videoLink = application.video;
    if (videoLink?.includes("youtube")) {
      let id = getId(videoLink);
      videoLink = "https://www.youtube.com/embed/" + id;
    }
  }

  return (
    <Stack
      sx={{
        ...theme.darkBg,
        maxHeight: "100vh",
        overflow: "hidden",
        width: "100%",
      }}
      alignItems="center"
    >
      <Stack
        sx={{
          ...theme.container,
          color: "white",
          position: "relative",
          height: "100%",
          borderLeft: "1px solid white",
        }}
      >
        <Stack
          sx={{
            height: "100vh",
            width: "100%",
            px: 2,
          }}
        >
          <Grid
            container
            columnSpacing={1}
            sx={{
              height: "100%",
            }}
          >
            <Grid
              item
              xs={8.5}
              sx={{
                overflowY: "scroll",
                height: "100%",
                pr: 1,
                pb: 10,
              }}
            >
              <Stack
                sx={{
                  ...theme.font.h0,
                  mb: 1,
                  pt: 2,
                }}
              >
                {application?.startup_name ?? "No Company Name"}
              </Stack>
              <Stack
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  mb: 2,
                }}
              >
                <Box
                  component="a"
                  sx={{
                    ...theme.font.h5,
                    color: "white",
                  }}
                  href={
                    (application?.website?.includes("http") ? "" : "https://") +
                    application?.website
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {application?.website}
                </Box>
              </Stack>
              <Stack
                sx={{
                  ...theme.font.h2,
                }}
                mt={6}
              >
                Macros
              </Stack>
              <Stack spacing={6} mt={2}>
                {application &&
                  bigBoxes.map((v) => (
                    <InfoSection key={v[0]} title={v[1]}>
                      {application[v[0]]}
                    </InfoSection>
                  ))}
              </Stack>
              <Stack
                sx={{
                  ...theme.font.h2,
                }}
                mt={6}
              >
                Additional Info
              </Stack>
              <Stack spacing={6} mt={2}>
                {application &&
                  littleBoxes.map((v) => (
                    <InfoSection key={v[0]} title={v[1]}>
                      {application[v[0]]}
                    </InfoSection>
                  ))}
              </Stack>
            </Grid>
            <Grid
              item
              xs={3.5}
              sx={{
                height: "100%",
                overflowY: "auto",
                pb: 10,
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  position: "sticky",
                  right: 0,
                  top: 0,
                  pt: 1,
                }}
              >
                <SideCard
                  header="status"
                  text={scoreText[Number.parseInt(application?.score ?? 0)]}
                  icon={
                    <StarRate
                      htmlColor={
                        scoreColor[Number.parseInt(application?.score ?? 0)]
                      }
                    />
                  }
                />
                <SideCard
                  header="regional"
                  text={application?.closest_regional}
                  icon={<EmojiEvents />}
                />
                <SideCard
                  header="location"
                  text={application?.region}
                  icon={<LocationOn />}
                />
                <SideCard
                  header="airport"
                  text={application?.airport}
                  icon={<FlightTakeoff />}
                />
                <SideCard
                  header="contact"
                  text={application?.applier_name}
                  icon={<Person />}
                />
                <SideCard
                  header="email"
                  text={application?.applier_email}
                  icon={<Mail />}
                />
                <SideCard
                  header="phone"
                  text={application?.applier_phone}
                  icon={<Phone />}
                />
                <SideCard
                  header="video"
                  text={
                    <a target="_blank" href={application?.video}>
                      {application?.video}
                    </a>
                  }
                  icon={<VideoFile />}
                />
                <iframe
                  src={
                    application?.video &&
                    !application?.video?.includes("tigerlaunch")
                      ? application?.video?.includes("youtube") ||
                        application?.video?.includes("youtu.be")
                        ? "https://youtube.com/embed/" +
                          getId(application?.video)
                        : application?.video
                      : "https://i0.wp.com/www.alphr.com/wp-content/uploads/2022/09/ikaw-lang-1.png?resize=650%2C320&ssl=1"
                  }
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    aspectRatio: "16 / 9",
                  }}
                />
                <BigButton
                  sx={{
                    width: "100%",
                    textAlign: "left",
                  }}
                  text="To Dashboard"
                  onClick={() => navigate("/dashboard")}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          sx={{
            position: "sticky",
            p: 1,
            top: "auto",
            bottom: 0,
            borderTop: "1px solid white",
            ...theme.darkBg,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Stack
            direction="row"
            sx={{
              height: "3rem",
            }}
            alignItems="center"
            spacing={2}
          >
            <Logo />
            <Stack
              sx={{
                ...theme.font.h5,
                fontFamily: "Rubik",
                fontVariationSettings: "'wght' 900",
              }}
            >
              Sorting
            </Stack>
          </Stack>
          <Stack direction="row">
            <SortButton text="Skip" onClick={getCompany} />
            <SortButton
              text="Mark Red"
              color="#ff6961"
              onClick={() => updateScore(3)}
            />
            <SortButton
              text="Mark Yellow"
              color="#dded66"
              onClick={() => updateScore(2)}
            />
            <SortButton
              text="Mark Green"
              color="#77dd77"
              onClick={() => updateScore(1)}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function SortButton(props) {
  const { text, color, onClick } = props;
  const theme = useTheme();

  return (
    <Box
      component={motion.button}
      sx={{
        ...theme.sortButton,
        borderLeft: "none",
        borderTop: "none",
        borderRight: "none",
        borderBottom: "none",
        py: 2,
        px: 2,
        minWidth: "8rem",
      }}
      animate={{
        background: color ?? "rgba(0, 0, 0, 0.08)",
        color: color ? "black" : "white",
      }}
      whileHover={{
        filter: "brightness(65%)",
      }}
      transition={{
        type: "spring",
        stiffness: "250",
        damping: "40",
      }}
      onClick={onClick}
    >
      {text}
    </Box>
  );
}
export function SideCard(props) {
  const { icon, text, header, onClick } = props;
  const theme = useTheme();

  return (
    <Stack
      component={motion.div}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        ...theme.font.sortButton,
        position: "relative",
        cursor: onClick ? "pointer" : "",
      }}
      whileHover={
        onClick
          ? {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            }
          : {}
      }
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          color: theme.darkColor,
          textTransform: "uppercase",
          fontFamily: "Rubik",
          fontVariationSettings: "'wght' 700",
          lineHeight: "0.8rem",
          ...theme.sideCard,
        }}
      >
        {header}
      </Box>
      <Stack
        sx={{
          px: 2,
          py: 1.5,
          overflow: "hidden",
          wordBreak: "break-word",
          position: "relative",
          zIndex: 1,
        }}
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Stack>{icon}</Stack>
        <Stack>{text}</Stack>
      </Stack>
    </Stack>
  );
}
export function InfoSection(props) {
  const { title, children } = props;
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            height: "4px",
            width: "2rem",
            borderTop: "4px solid white",
          }}
        />
        <Stack
          sx={{
            ...theme.font.h4,
          }}
        >
          {title}
        </Stack>
      </Stack>
      <Stack
        sx={{
          ...theme.font.h5,
          fontFamily: "Karla",
          pl: 5,
          lineHeight: "150%",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}

export default Sorting;
