import React, { useContext } from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { LayoutContext } from "../layout/Layout";
import Nassau from "../img/nassau.jpg";
import PartnersImg from "../img/Sponsors.jpg";
import { motion } from "framer-motion";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const animations = {
  heroArrow: {
    inactive: {
      bottom: "-2rem",
      opacity: 0,
    },
    active: {
      bottom: "2rem",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0,
      },
    },
  },
  heroNames: {
    inactive: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0,
        delayChildren: 0.25,
        staggerChildren: 0.1,
      },
    },
  },
  heroName: {
    inactive: {
      bottom: -64,
      opacity: 0,
    },
    active: {
      bottom: 0,
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
      },
    },
  },
};

export default function Partners(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          height: "100vh",
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
            height: "100vh",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              position: "absolute",
              background: `url(${Nassau})`,
              height: "100vh",
              maxWidth: "95vw",
              backgroundSize: "cover",
              overflow: "hidden",
              aspectRatio: "16 / 9",
              backgroundPosition: "41% 50%",
              borderRadius: "10%",
            }}
          >
            <motion.div
              className="w-100 h-100"
              initial={{
                background:
                  "radial-gradient(circle, rgba(30,30,48,0.5) 0%, rgba(30,30,48,1) 0%)",
              }}
              animate={{
                background:
                  "radial-gradient(circle, rgba(30,30,48,0.5) 0%, rgba(30,30,48,1) 60%)",
              }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 25,
                delay: 0.6,
              }}
            />
          </Box>
          <Box
            component={motion.div}
            sx={{
              position: "absolute",
              margin: "0 auto",
              ...theme.light,
            }}
            variants={animations.heroArrow}
            initial="inactive"
            animate={layout.scrollY > 0 ? "inactive" : "active"}
          >
            <KeyboardArrowDownOutlined
              sx={{
                transform: {
                  xs: "scale(1) transform(0, 32)",
                  md: "scale(2)",
                },
              }}
            />
          </Box>
          <Stack
            sx={{
              position: "relative",
            }}
            alignItems="center"
          >
            <Stack
              component={motion.div}
              variants={animations.heroArrow}
              initial="inactive"
              animate="active"
              sx={{
                ...theme.font.h2,
                color: "white",
                textAlign: "center",
                py: 2,
                mb: 2,
                borderBottom: "1px solid white",
              }}
            >
              TigerLaunch is made possible thanks to generous support from:
            </Stack>
            <Stack
              component={motion.div}
              variants={animations.heroNames}
              initial={"inactive"}
              animate={"active"}
              alignItems="center"
              sx={{
                ...theme.font.h4,
                color: "white",
              }}
              spacing={1}
            >
              <Stack
                component={motion.div}
                variants={animations.heroName}
                sx={{
                  ...theme.font.h3,
                  py: 2,
                  position: "relative",
                }}
              >
                Howard Cox '64
              </Stack>
              {[
                "Sumir Chadha, Laura Overdeck, Liz Meyers, Al Hurley, Robert Kraybill",
                "We_Start",
                "NUS entrepreneurship (UNICON)",
                "Kickstart Global",
                "Rice Business Plan Competition",
                "CORE",
                "UC Berkeley’s Enactus",
                "Entrepreneurship Cell IIT Bombay",
              ].map((v) => (
                <Stack
                  key={v}
                  component={motion.div}
                  variants={animations.heroName}
                  sx={{
                    position: "relative",
                  }}
                >
                  {v}
                </Stack>
              ))}
              <Stack
                sx={{
                  ...theme.font.h4,
                  pt: 2,
                  position: "relative",
                }}
                component={motion.div}
                variants={animations.heroName}
              >
                Company partners below.
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          ...theme.lightBg,
          pt: 8,
        }}
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          alignItems="center"
        >
          <img
            style={{
              width: "100%",
              height: "auto",
              boxShadow: "0 0.5rem 0.5rem grey",
            }}
            alt="partners"
            src={PartnersImg}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
