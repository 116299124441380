import React, { useContext, useState, useEffect, useRef } from "react";
import "../scss/core.scss";
import "../scss/home.scss";
import { motion, useMotionValue, animate } from "framer-motion";
import Nassau from "../img/nassau.jpg";
import waivethewait from "../img/companies/waivethewait.png";
import yogi from "../img/companies/yogi.png";
import cimu from "../img/companies/cimu.jpeg";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadLinksPreset } from "tsparticles-preset-links";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import logoLarge from "../img/logo-large-transparent.png";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { LayoutContext } from "../layout/Layout";
import ParticleWrapper from "../generic/ParticleWrapper";
import { NavButton } from "../layout/Nav";
import CompanyCard from "./CompanyCard";

const animations = {
  slideUp: {
    inactive: {
      top: 0,
      opacity: 1,
    },
    active: {
      top: "-256px",
      opacity: 0,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 100,
        damping: 10,
        delay: 0.2,
      },
    },
  },
  slideDown: {
    inactive: {
      top: 0,
      opacity: 1,
    },
    active: {
      top: "256px",
      opacity: 0,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 100,
        damping: 10,
        delay: 0.4,
      },
    },
  },
  heroText: {
    inactive: {
      top: "32px",
      opacity: 0,
    },
    active: {
      top: 0,
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0.5,
      },
    },
  },
  heroExtra: {
    inactive: {
      top: "32px",
      opacity: 0,
    },
    active: {
      top: 0,
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0.65,
      },
    },
  },
  heroArrow: {
    inactive: {
      bottom: "-2rem",
      opacity: 0,
    },
    active: {
      bottom: "2rem",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 15,
        delay: 0,
      },
    },
  },
  describeBody: {
    inactive: {
      opacity: 0.01,
    },
    active: {
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 25,
        mass: 1,
        delay: 0,
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  },
  describeChild: {
    inactive: {
      opacity: 0.01,
      top: "4rem",
    },
    active: {
      opacity: 1,
      top: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 25,
      },
    },
  },
};

function Countdown({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState({});
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(targetDate) - +new Date();
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
        setIsLoading(false);
      } else {
        setTimeLeft({});
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.font.h3,
          fontSize: {
            md: "300%",
            xs: "250%",
          },
          color: theme.orange,
          fontVariationSettings: "'wght' 750",
        }}
        alignItems="center"
      >
        Applications Due:
      </Stack>
      <Stack
        sx={{
          fontSize: {
            md: "450%",
            xs: "300%",
          },
          color: "white",
          mt: 4,
          fontVariationSettings: "'wght' 750",
        }}
        alignItems="center"
      >
        {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
        {timeLeft.seconds}s
      </Stack>
    </Stack>
  );
}

export default function Home(props) {
  const theme = useTheme();
  const layout = useContext(LayoutContext);
  const fund = useMotionValue(0);
  const [fundBoxRef, fundBoxInView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });
  const numberRef = useRef();

  useEffect(() => {
    if (fundBoxInView) {
      const node = numberRef.current;
      animate(fund, 1400000000, {
        type: "tween",
        ease: "easeInOut",
        duration: 2,
        delay: 0.5,
        onUpdate(v) {
          node.textContent = Math.floor(v).toLocaleString("en-US");
        },
      });
    }
  }, [fundBoxInView]);

  return (
    <div>
      <Stack
        sx={{
          ...theme.darkBg,
          height: "100vh",
          overflow: "hidden",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            position: "absolute",
            background: `url(${Nassau})`,
            height: "100vh",
            maxWidth: "100vw",
            backgroundSize: "cover",
            overflow: "hidden",
            aspectRatio: "16 / 9",
            backgroundPosition: "center",
            border: "none",
          }}
        >
          <motion.div
            className="w-100 h-100"
            initial={{
              background:
                "radial-gradient(circle, rgba(30,30,48,0.5) 0%, rgba(30,30,48,1) 0%)",
            }}
            animate={{
              background:
                "radial-gradient(circle, rgba(30,30,48,0.5) 0%, rgba(30,30,48,1) 60%)",
            }}
            transition={{
              type: "spring",
              stiffness: 200,
              damping: 25,
              delay: 0.6,
            }}
          />
        </Box>
        <Stack
          sx={{
            position: "relative",
            overflow: "hidden",
            ...theme.container,
          }}
        >
          <Box
            component={motion.div}
            variants={animations.slideUp}
            initial="inactive"
            animate="active"
            sx={{
              position: "absolute",
              left: 0,
              width: "40%",
              height: "100%",
              ...theme.darkBg,
            }}
          />
          <Box
            component={motion.div}
            variants={animations.slideDown}
            initial="inactive"
            animate="active"
            sx={{
              position: "absolute",
              top: 0,
              left: "40%",
              width: "60%",
              height: "100%",
              ...theme.darkBg,
            }}
          />
          <Box component="img" className="w-100" src={logoLarge} />
        </Stack>
        <Stack
          sx={{
            ...theme.light,
            ...theme.heroText,
            fontFamily: "Montserrat",
            position: "relative",
            textAlign: "center",
          }}
          component={motion.div}
          variants={animations.heroText}
          initial={"inactive"}
          animate={"active"}
        >
          The World's Largest Student-Run Entrepreneurship Competition
          {/* <Stack
            sx={{
              ...theme.light,
              ...theme.heroText,
              fontFamily: "Montserrat",
              position: "absolute",
              textAlign: "center",
              left: 0,
              right: 0,
              margin: "0 auto",
              padding: "15px",
              mt: {
                md: 8,
                xs: 4,
              },
              py: 4,
              borderRadius: "0.5rem",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
            component={motion.div}
            variants={animations.heroExtra}
            initial={"inactive"}
            animate={"active"}
            justifyContent="center"
          >
            <p style={{ margin: 0 }}>
              Submissions for TigerLaunch 2024 are due on November 10th!
            </p>
            <Stack
              sx={{
                ...theme.font.h5,
                mt: 2,
              }}
              spacing={2}
            ></Stack>
          </Stack> */}
        </Stack>
        <Box
          component={motion.div}
          sx={{
            position: "absolute",
            margin: "0 auto",
            ...theme.light,
          }}
          variants={animations.heroArrow}
          initial="inactive"
          animate={layout.scrollY > 0 ? "inactive" : "active"}
        >
          <KeyboardArrowDownOutlined
            sx={{
              transform: {
                xs: "scale(1) transform(0, 32)",
                md: "scale(2)",
              },
            }}
          />
        </Box>
      </Stack>
      <Stack
        sx={{
          pt: 16,
          pb: 8,
          position: "relative",
          ...theme.blackBg,
        }}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={8}
      >
        <Countdown targetDate="2024-11-10T23:59:59" />
        <Box
          sx={{
            height: "min(60rem, 60vh)",
            position: "relative",
            borderRadius: "1rem",
            zIndex: 1024,
            ...theme.container,
          }}
          component={"iframe"}
          src={"https://www.youtube.com/embed/2Yp_DDOt-jM"}
        />
        <Stack
          sx={{
            ...theme.container,
            ...theme.font.h3,
            textAlign: "center",
            color: "white",
            px: 4,
          }}
          justifyContent="center"
          alignItems="center"
        >
          {/*<Stack*/}
          {/*  sx={{*/}
          {/*    fontSize: {*/}
          {/*      md: "250%",*/}
          {/*      xs: "200%",*/}
          {/*    },*/}
          {/*    color: theme.orange,*/}
          {/*    mt: 8,*/}
          {/*    fontVariationSettings: "'wght' 750",*/}
          {/*  }}*/}
          {/*  alignItems="center"*/}
          {/*>*/}
          {/*  Apply Now!*/}
          {/*</Stack>*/}
          <Stack
            ref={fundBoxRef}
            sx={{
              ...theme.font.h3,
              color: "white",
            }}
          >
            Applications due November 10th, 2024
          </Stack>

          <Stack
            sx={{
              fontSize: {
                md: "250%",
                xs: "200%",
              },
              mt: 8,
              color: theme.orange,
              fontVariationSettings: "'wght' 750",
              textShadow: "0 0.125rem 0.125rem white",
            }}
            alignItems="center"
            direction="row"
          >
            $<span ref={numberRef}></span>
          </Stack>
          <Stack
            ref={fundBoxRef}
            sx={{
              ...theme.font.h3,
              color: "white",
            }}
          >
            Total venture funding raised since 2014
          </Stack>
          <Stack
            sx={{
              fontSize: {
                md: "250%",
                xs: "200%",
              },
              color: theme.mediumOrange,
              mt: 8,
              fontVariationSettings: "'wght' 750",
            }}
            alignItems="center"
          >
            Worldwide
          </Stack>
          <Stack
            ref={fundBoxRef}
            sx={{
              ...theme.font.h3,
              color: "white",
            }}
          >
            Regional contests in NYC, San Francisco, Houston, Singapore, London,
            and Paris.
          </Stack>
          <Stack
            sx={{
              fontSize: {
                md: "250%",
                xs: "200%",
              },
              color: theme.lightOrange,
              mt: 8,
              fontVariationSettings: "'wght' 750",
            }}
            alignItems="center"
          >
            YCombinator
          </Stack>
          <Stack
            ref={fundBoxRef}
            sx={{
              ...theme.font.h3,
              color: "white",
            }}
          >
            Our contestants get selected for prestigious incubators every year,
            without fail.
          </Stack>
          <br />
          <Stack
            sx={{
              fontSize: {
                md: "200%",
                xs: "150%",
              },
              color: theme.lightOrange,
              mt: 8,
              fontVariationSettings: "'wght' 750",
            }}
            alignItems="center"
          >
            Winners of the Howard Cox ’64 Entrepreneurship Prize 2023
          </Stack>
          <br />
          <Stack
            sx={{
              width: "100%",
              textAlign: "left",
            }}
            spacing={4}
          >
            <CompanyCard
              name={"CIMU"}
              desc={
                "Cimu helps online, middle-market fashion retailers reduce returns by connecting consumers with easy and accessible clothing alterations."
              }
              imgUrl={cimu}
              tags={[]}
            />
            <CompanyCard
              name={"Admit Yogi"}
              desc={
                "AdmitYogi is democratizing college admissions for millions of high schoolers with the power of Big Data and Artificial Intelligence."
              }
              imgUrl={yogi}
              tags={[]}
            />
            <CompanyCard
              name={"Waive The Wait"}
              desc={
                "WaiveTheWait is a patient communications company that optimizes clinical operations by automating routine and complex tasks to reduce operational costs, increase revenue, and reduce staff burnout."
              }
              imgUrl={waivethewait}
              tags={[]}
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            height: "min(24rem, 25vh)",
            position: "relative",
            borderRadius: "1rem",
            zIndex: 1024,
            ...theme.container,
          }}
          component={"iframe"}
          src="https://open.spotify.com/embed/show/3qgWy4ZsXiEp8I7gapIQha?utm_source=generator&theme=0"
          frameBorder="0"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </Stack>
      <Stack
        sx={{
          ...theme.lightBg,
          py: 8,
          color: "black",
          overflow: "hidden",
        }}
        direction="column"
        alignItems="center"
        spacing={8}
      >
        <TriStripe>Launch your startups with TigerLaunch!</TriStripe>
        <Stack
          sx={{
            fontFamily: "'Rubik', sans-serif",
            width: "100%",
          }}
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Describe color={theme.lightOrange} title="Connections">
            <ul>
              <li>Access a community of international founders</li>
              <li>Hear ideas from all walks of life</li>
              <li>Build rapport in different industries</li>
            </ul>
          </Describe>
          <Describe color={theme.mediumOrange} title="Mentorship">
            <ul>
              <li>Get feedback from industry leaders</li>
              <li>Secure a foundation for promotions</li>
              <li>Network with top venture capitalists</li>
            </ul>
          </Describe>
          <Describe color={theme.orange} title="Funding">
            <ul>
              <li>$60,000 cash prize, no strings attached</li>
            </ul>
          </Describe>
        </Stack>
      </Stack>
      {/* <Stack
        sx={{
          backgroundImage: `url(${Nassau})`,
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "100vh",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            backdropFilter: "brightness(0.5)",
            py: 8,
            color: "white",
          }}
          alignItems="center"
        >
          <Stack
            sx={{
              ...theme.font.h1,
              py: 4,
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              width: "100%",
            }}
            alignItems="center"
          >
            <Stack
              sx={{
                ...theme.container,
                textAlign: "center",
              }}
            >
              2022 Winners
            </Stack>
          </Stack>
        </Stack>
      </Stack> */}
    </div>
  );
}

function TriStripe(props) {
  const theme = useTheme();

  return (
    <Stack
      component={motion.div}
      sx={{
        width: "100vw",
        overflow: "hidden",
      }}
      variants={{
        inactive: {
          opacity: 1,
        },
        active: {
          opacity: 1,
          transition: {
            delayChildren: 0.25,
            staggerChildren: 0.2,
            type: "spring",
            stiffness: 200,
            damping: 25,
            mass: 1,
            delay: 0,
          },
        },
      }}
      initial={"inactive"}
      whileInView={"active"}
      viewport={{ once: true }}
      direction="column"
      alignItems="center"
      spacing={1}
    >
      <Box
        component={motion.div}
        variants={{
          inactive: {
            left: "-42.5%",
          },
          active: {
            left: 0,
          },
        }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 25,
        }}
        sx={{
          width: "42.5%",
          alignSelf: "flex-start",
          ...theme.lightOrangeBg,
          height: "0.5rem",
          borderRadius: "0 0.5rem 0.5rem 0",
          position: "relative",
        }}
      />
      <Box
        component={motion.div}
        variants={{
          inactive: {
            left: "-98%",
          },
          active: {
            left: 0,
            transition: {
              type: "spring",
              stiffness: 200,
              damping: 25,
              delay: 0,
            },
          },
        }}
        initial="inactive"
        whileInView="active"
        viewport={{ once: true }}
        className="col-cc"
        sx={{
          width: "100%",
          alignSelf: "flex-start",
          ...theme.mediumOrangeBg,
          padding: "2rem",
          color: "white",
          position: "relative",
          fontFamily: "'Rubik', monospace",
          fontVariationSettings: "'wght' 500",
        }}
      >
        <Box
          className="col-cc"
          sx={{
            ...theme.container,
            textAlign: "center",
          }}
        >
          <h3>{props.children}</h3>
        </Box>
      </Box>
      <Box
        component={motion.div}
        variants={{
          inactive: {
            right: "-45%",
          },
          active: {
            right: 0,
          },
        }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 25,
        }}
        sx={{
          width: "45%",
          alignSelf: "flex-end",
          ...theme.orangeBg,
          height: "0.5rem",
          borderRadius: "0.5rem 0 0 0.5rem",
          position: "relative",
        }}
      />
      <Box
        component={motion.div}
        variants={{
          inactive: {
            right: "-42.5%",
          },
          active: {
            right: 0,
          },
        }}
        transition={{
          type: "spring",
          stiffness: 200,
          damping: 25,
        }}
        sx={{
          width: "42.5%",
          alignSelf: "flex-end",
          ...theme.orangeBg,
          height: "0.5rem",
          borderRadius: "0.5rem 0 0 0.5rem",
          position: "relative",
        }}
      />
    </Stack>
  );
}

function Describe(props) {
  const theme = useTheme();
  const { color, title } = props;

  return (
    <Stack
      component={motion.div}
      variants={animations.describeBody}
      initial="inactive"
      whileInView="active"
      viewport={{ once: true }}
      sx={{ ...theme.container, position: "relative" }}
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems="flex-start"
      spacing={2}
    >
      <Stack
        component={motion.div}
        variants={animations.describeChild}
        sx={{
          position: "relative",
          borderLeft: `0.5rem solid ${color}`,
          color: color,
          width: "18rem",
          textAlign: "center",
          padding: "1rem 1rem 1rem 0",
          borderRadius: "0 0.5rem 0.5rem 0",
          ...theme.threeSectionHeader,
          ...theme.blackBg,
        }}
      >
        {title}
      </Stack>
      <Stack
        component={motion.div}
        variants={animations.describeChild}
        sx={{
          position: "relative",
          borderRight: `0.5rem solid ${color}`,
          ...theme.blackBg,
          pr: 4,
          pl: 1,
          py: 2,
          flexGrow: 1,
          borderRadius: "0.5rem 0 0 0.5rem",
          fontFamily: "'Rubik', sans-serif",
          fontVariationSettings: "'wght', 900",
          color: "white",
          width: "80%",
          alignSelf: {
            xs: "flex-end",
            md: "",
          },
        }}
      >
        {props.children}
      </Stack>
    </Stack>
  );
}
