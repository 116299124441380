import React from "react";
import {motion} from "framer-motion";
import {Box} from "@mui/material";

export default function BigButton(props) {
    const {text, onClick, sx, children} = props;

    return <Box component={motion.button} sx={{
        width: "fit-content",
        px: 2,
        py: 2,
        backgroundColor: "rgba(0, 0, 0, 0.16)",
        color: "white",
        borderLeft: "4px solid white",
        borderTop: "none",
        borderRight: "none",
        borderBottom: "none",
        ...sx,
    }} whileHover={{
        backgroundColor: "rgba(255, 255, 255, 0.04)"
    }} transition={{
        type: "spring",
        stiffness: "250",
        damping: "40",
    }} onClick={onClick}>
        {text}{children}
    </Box>
}