import { Box, Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";

function CompanyCard(props) {
  const { name, desc, imgUrl, tags } = props;

  const theme = useTheme();

  return (
    <Stack
      sx={{
        color: "white",
        width: "100%",
        p: 4,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      }}
      direction="row"
      spacing={4}
    >
      <Stack>
        <Box
          sx={{
            width: {
              md: "10rem",
              xs: "6rem",
            },
            height: "auto",
            aspectRatio: "1 / 1",
            objectFit: "contain",
            p: 2,
            borderTop: "4px solid white",
          }}
          component="img"
          src={imgUrl}
        />
      </Stack>
      <Stack
        sx={{
          flexGrow: 1,
        }}
        spacing={1}
      >
        <Stack
          sx={{
            ...theme.font.h2,
          }}
        >
          {name}
        </Stack>
        <Stack
          sx={{
            ...theme.font.h5,
            pb: 1,
            lineHeight: "150%",
          }}
        >
          {desc}
        </Stack>
        <Stack direction="row" spacing={1}>
          {tags.map((v, i) => {
            return (
              <Box
                sx={{
                  ...theme.badge,
                  backgroundColor: i === 0 ? theme.orange : theme.mediumOrange,
                  color: theme.darkColor,
                }}
              >
                {v}
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CompanyCard;
