import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { rgbToHex, Stack, Typography, useTheme } from "@mui/material";
import "../scss/layout.scss";
import "../scss/core.scss";
import Logo from "../generic/Logo.js";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutContext } from "./Layout";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

const heightMd = "10rem";
const heightSm = "6rem";
const heightXs = "4rem";

const animations = {
  navArrow: {
    inactive: {
      top: "-160px",
      opacity: 0,
    },
    active: {
      top: "0px",
      opacity: 1,
      transition: {
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 25,
        delay: 0,
      },
    },
  },
};

const links = [
  // ["About", "/home/about"],
  // ["Finals", "/home/finals"],
  ["Partners", "/home/partners"],
  ["Companies", "/home/companies"],
  ["team", "/home/team"],
  // ["login", "/login"],
];

export default function Nav(props) {
  const [hovering, setHovering] = useState(false);
  const theme = useTheme();
  const layout = useContext(LayoutContext);

  return (
    <Stack
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      sx={{
        position: "fixed",
        overflow: "hidden",
        width: "100%",
        top: 0,
        zIndex: 65535,
      }}
      animate={{ top: layout.scrollY === 0 || hovering ? "0" : "-160px" }}
      transition={{
        type: "spring",
        mass: 1,
        stiffness: 200,
        damping: 25,
        delay: 0,
      }}
      component={motion.div}
    >
      <Stack
        component={motion.div}
        className="w-100"
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        display={{
          md: "flex",
        }}
        sx={{
          // ...theme.darkBg,
          height: {
            md: heightMd,
            sm: heightSm,
            xs: heightXs,
          },
          position: "relative",
          px: 2,
        }}
        animate={{
          backgroundColor:
            layout.scrollY === 0 ? "rgba(0, 0, 0, 0)" : "rgba(30, 30, 48)",
        }}
        spacing={4}
      >
        <Stack
          alignSelf="flex-start"
          sx={{
            flexGrow: 1,
            position: "absolute",
            top: 0,
            bottom: 0,
            margin: "auto 0",
            pt: 2,
            height: {
              md: heightMd,
              sm: heightSm,
              xs: heightXs,
            },
          }}
        >
          <Logo />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignSelf="center"
          alignItems="center"
          spacing={{
            md: 4,
            sm: 2,
            xs: 0.5,
          }}
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            margin: "auto 0",
            height: {
              md: heightMd,
              sm: heightSm,
              xs: heightXs,
            },
            pt: 2,
          }}
          style={{
            marginTop: 0,
          }}
        >
          {links.map((v) => {
            return (
              <>
                <NavButton to={v[1]} key={v[1]}>
                  {v[0]}
                </NavButton>
              </>
            );
          })}
        </Stack>
        <Stack
          alignSelf="flex-end"
          alignItems="center"
          justifyContent="center"
          sx={{
            ...theme.light,
            position: "absolute",
            top: 0,
            bottom: 0,
            margin: "auto 0",
            height: {
              md: heightMd,
              sm: heightSm,
              xs: heightXs,
            },
            right: {
              md: "4rem",
              sm: "2rem",
              xs: "1rem",
            },
            pt: 2,
          }}
          style={{
            marginTop: 0,
          }}
        >
          <NavButton
            to="/apply"
            customSx={{
              backgroundColor: theme.orange,
              color: "#1e1e30",
              px: {
                md: 4,
                xs: 1,
              },
              borderRadius: "0 0.5rem 0 0.5rem",
            }}
            customHover={{
              backgroundColor: `rgba(249, 157, 28, 1)`,
              color: "rgb(255, 255, 255)",
            }}
          >
            Apply
          </NavButton>
        </Stack>
      </Stack>
      <Stack
        component={motion.div}
        sx={{
          background:
            layout.scrollY < window.innerHeight - 160
              ? "rgba(255, 255, 255, 0)"
              : "linear-gradient(180deg, rgba(30,30,48,1) 0%, rgba(30,30,48,1) 25%, rgba(30,30,48,0) 75%)",
          height: "2rem",
          position: "relative",
          zIndex: -1,
        }}
        justifyContent="center"
        alignItems="center"
        variants={animations.navArrow}
        initial={"inactive"}
        animate={layout.scrollY > 0 ? "active" : "inactive"}
      >
        <KeyboardArrowDownOutlined htmlColor="#ffffff" />
      </Stack>
    </Stack>
  );
}

export function NavButton(props) {
  const { to, customSx, customHover } = props;
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Stack
      component={motion.div}
      whileHover={
        customHover ?? {
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          color: "rgb(255, 255, 255)",
        }
      }
      onClick={() => navigate(to)}
      sx={{
        p: {
          md: 2,
          xs: 1,
        },
        cursor: "pointer",
        color: "#9f9faa",
        ...customSx,
      }}
    >
      <Stack
        sx={{
          margin: 0,
          fontWeight: "700",
          fontFamily: "'Montserrat', sans-serif",
          letterSpacing: "1px",
          textTransform: "uppercase",
          ...theme.font.nav,
        }}
      >
        {props.children}
      </Stack>
    </Stack>
  );
}
