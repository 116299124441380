import React, { useState, useEffect, useCallback } from "react";
import { db, auth, provider } from "../firebase.js";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import {
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import Logo from "../generic/Logo";
import BigButton from "../generic/BigButton";
import { signInWithRedirect } from "firebase/auth";
import { Check, Close, Info } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import { AnimateSharedLayout, motion } from "framer-motion";

function EditTeam() {
  const appsRef = collection(db, "apps");
  const authRef = collection(db, "user-emails");
  const auth = getAuth();
  const theme = useTheme();

  return <>Edit Team</>;
}

export default EditTeam;
