import React, { useState, useEffect } from "react";
import { db, auth, provider } from "../firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";

import {
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
  writeBatch,
} from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import Logo from "../generic/Logo";
import BigButton from "../generic/BigButton";
import { signInWithRedirect } from "firebase/auth";
import { Check, Close, Info } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import { AnimateSharedLayout, motion } from "framer-motion";

function NewUser() {
  const appsRef = collection(db, "apps");
  const authRef = collection(db, "user-emails");
  const theme = useTheme();
  let [userEmail, setUserEmail] = useState("");
  let [judgeEmails, setJudgeEmails] = useState("");
  let [password, setPassword] = useState("");

  const createUser = async () => {
    if (userEmail.includes(" ")) {
      let emails = userEmail.split(" ");
      emails.forEach(async (email) => {
        let userObj = {
          email: email,
          TL: true,
        };
        await addDoc(authRef, userObj);
      });
      window.alert(`${emails.length} users Created!`);
    } else {
      let userObj = {
        email: userEmail,
        TL: true,
      };
      await addDoc(authRef, userObj);
      window.alert(`User Created!\nEmail: ${userEmail}`);
    }

    setUserEmail("");
  };
  const createJudgeUsers = () => {
    let emails = judgeEmails.split(" ");
    emails.forEach((email) => {
      createUserWithEmailAndPassword(auth, email, password).then((user) => {
        console.log(user);
      });
    });
    window.alert(`${emails.length} users Created!`);
  };

  useEffect(() => {}, []);

  return (
    <Stack
      sx={{
        ...theme.darkBg,
      }}
      alignItems="center"
    >
      <Stack
        sx={{
          ...theme.container,
          minHeight: "100vh",
          borderLeft: "1px solid white",
        }}
      >
        <Stack
          sx={{
            ...theme.font.h1,
            ...theme.light,
            fontFamily: "'Rubik'",
            flexShrink: 1,
            py: 8,
            px: 4,
          }}
          direction="row"
          spacing={{
            md: 4,
            xs: 2,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              height: "8rem",
            }}
          >
            <Logo />
          </Stack>
          <Stack
            sx={{
              height: "100%",
              py: 2,
            }}
            justifyContent="space-between"
            spacing={1}
          >
            <Stack>Add Users</Stack>
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Create a new Tiger Launch user.
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            px: 4,
          }}
          spacing={2}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Email:
            </Stack>
            <Box
              component="input"
              type="email"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              sx={{
                ...theme.subQuestion,
              }}
            />
          </Stack>
          <Stack
            sx={{
              pb: 1,
            }}
          >
            <BigButton
              text={"Submit"}
              onClick={userEmail.length > 0 ? createUser : () => {}}
            />
          </Stack>
        </Stack>
        <Stack
          sx={{
            px: 4,
          }}
          spacing={2}
        >
          <br />
          <Stack
            sx={{
              ...theme.font.h4,
              ...theme.light,
            }}
          >
            Create New Judge Logins
            <Stack
              sx={{
                ...theme.font.h5,
                ...theme.light,
              }}
            >
              This will log you out
            </Stack>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Emails:
            </Stack>
            <Box
              component="input"
              type="email"
              value={judgeEmails}
              placeholder="email1 email2 email3"
              onChange={(e) => {
                setJudgeEmails(e.target.value);
              }}
              sx={{
                ...theme.subQuestion,
              }}
            />
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center">
            <Stack
              sx={{
                ...theme.font.h4,
                ...theme.light,
              }}
            >
              Password:
            </Stack>
            <Box
              component="input"
              type="text"
              value={password}
              placeholder="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              sx={{
                ...theme.subQuestion,
              }}
            />
          </Stack>
          <Stack
            sx={{
              pb: 1,
            }}
          >
            <BigButton
              text={"Create Users"}
              onClick={
                judgeEmails.length > 0 && password.length > 0
                  ? createJudgeUsers
                  : () => {}
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <br />
      <br />
    </Stack>
  );
}

export default NewUser;
