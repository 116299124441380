import React, { useReducer, useState } from "react";
import { db } from "../../firebase.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { collection, addDoc } from "firebase/firestore";
import styles from "./Apply.module.css";
import LongAnswer from "../LongAnswer.js";
import Autocomplete from "react-google-autocomplete";
import getClosestRegional from "../getClosestRegional.js";
import { Box, Stack, useTheme } from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import Logo from "../../generic/Logo.js";
import { useEffect } from "react";
import ApplicationKeys from "../ApplicationKeys.js";

const initialInputs = ApplicationKeys();

const inputsReducer = (state, action) => {
  return { ...state, ...action };
};

function Apply() {
  const appsRef = collection(db, "apps");
  const mailRef = collection(db, "mail");
  const theme = useTheme();

  const [inputs, dispatch] = useReducer(inputsReducer, initialInputs);
  const [error, setError] = useState(false);
  const [applicationPage, setApplicationPage] = useState(0);

  const Push = async () => {
    setApplicationPage(-1);
    let closestRegional = await getClosestRegional(inputs.region);
    addDoc(appsRef, {
      ...inputs,
      score: 0,
      last_access: null,
      closest_regional: closestRegional,
    });
    addDoc(mailRef, {
      to: [inputs.applier_email],
      message: {
        subject: "Thank you for submitting your application to TigerLaunch!",
        text: "Your TigerLaunch application has been successfully submitted! We will be in touch by early December with the status of your application. If you have any questions in the meantime, please contact jp6294@princeton.edu and hnabeshima@princeton.edu.",
      },
    });

    // changeApplicationPage(1);
  };

  useEffect(() => {}, []);

  const industries = [
    "Agriculture",
    "Data & Services",
    "Education",
    "Entertainment",
    "Finanical Services",
    "Government",
    "Healthcare",
    "Legal",
    "Media",
    "Security",
    "Apps & Services",
    "Dev Tools",
    "Energy",
    "Fashion",
    "Food / Well-being",
    "Hardware",
    "Infrastructure",
    "Marketplace",
    "Productivity",
    "Transport",
  ];

  // const regions = ["Americas", "Europe", "Asia", "Other"];

  const formFields = [
    <Stack spacing={2}>
      <Stack sx={{ ...theme.question }}>What is your Startup's name?</Stack>
      <input
        placeholder="Startup Name"
        value={inputs.startup_name}
        onChange={(e) => dispatch({ startup_name: e.target.value })}
      />
    </Stack>,
    <Stack spacing={2}>
      <Stack sx={{ ...theme.question }}>
        Does your company have a founder who is a student enrolled full-time at
        an undergraduate, graduate, or doctoral program with a graduation date
        of December 2023 or later?
      </Stack>
      <Stack
        component="label"
        className={styles.select}
        direction={"row"}
        sx={{
          borderLeft: "true" === inputs.has_student ? "4px solid white" : "",
        }}
      >
        <input
          style={{
            display: "none",
          }}
          type="radio"
          name="student-radio"
          value="true"
          checked={"true" === inputs.has_student}
          onChange={(e) => dispatch({ has_student: e.target.value })}
        />
        <Stack
          sx={{
            ...theme.font.h5,
          }}
        >
          Yes
        </Stack>
      </Stack>
      <Stack
        component="label"
        className={styles.select}
        direction="row"
        sx={{
          borderLeft: "false" === inputs.has_student ? "4px solid white" : "",
        }}
      >
        <input
          style={{
            display: "none",
          }}
          type="radio"
          name="student-radio"
          value="false"
          checked={"false" === inputs.has_student}
          onChange={(e) => dispatch({ has_student: e.target.value })}
        />
        <Stack
          sx={{
            ...theme.font.h5,
          }}
        >
          No
        </Stack>
      </Stack>
    </Stack>,
    <Stack spacing={2}>
      <Stack sx={{ ...theme.question }}>What is your company's website?</Stack>
      <Stack sx={{ ...theme.subQuestion }}>
        Provide a pitch deck or presentation if you don't have one.
      </Stack>
      <input
        placeholder="URL"
        value={inputs.website}
        onChange={(e) => dispatch({ website: e.target.value })}
      />
    </Stack>,
    <LongAnswer
      question="Tell us what university(s) your team attends."
      subquestion="If your team is based out of multiple universities, please list the university of the primary contact first"
      value={inputs.univeristies}
      onChange={(e) => dispatch({ universities: e.target.value })}
    />,
    <LongAnswer
      question="Name of Primary Contact"
      subquestion=""
      value={inputs.applier_name}
      onChange={(e) => dispatch({ applier_name: e.target.value })}
    />,
    <LongAnswer
      question="Email of Primary Contact"
      subquestion=""
      value={inputs.applier_email}
      onChange={(e) => dispatch({ applier_email: e.target.value })}
    />,
    <LongAnswer
      question="Phone Number of Primary Contact"
      subquestion=""
      value={inputs.applier_phone}
      onChange={(e) => dispatch({ applier_phone: e.target.value })}
    />,
    <Stack spacing={2}>
      <Stack
        sx={{
          ...theme.question,
        }}
      >
        Which city is your team primarily based in?
      </Stack>
      <Autocomplete
        apiKey={"AIzaSyAnLej2U3Lqud9-c3QPAMma2vJwEJL9xS0"}
        onPlaceSelected={(place) => {
          dispatch({ region: place.formatted_address });
        }}
        defaultValue={inputs.region}
      />
    </Stack>,
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack sx={{ ...theme.question }}>
          What is the closest major airport to your team?
        </Stack>
        <Stack sx={{ ...theme.subQuestion }}>
          Please provide the 3 letter airport code
        </Stack>
      </Stack>
      <input
        placeholder="LAX"
        value={inputs.airport}
        onChange={(e) => dispatch({ airport: e.target.value })}
      />
    </Stack>,
    <LongAnswer
      question="Additional Founder Names (up to 3)"
      subquestion=""
      value={inputs.additional_names}
      onChange={(e) => dispatch({ additional_names: e.target.value })}
    />,
    <LongAnswer
      question="Additional Founder Emails (up to 3)"
      subquestion=""
      value={inputs.additional_emails}
      onChange={(e) => dispatch({ additional_emails: e.target.value })}
    />,
    <LongAnswer
      key={1}
      question="Company Synopsis. (max 200 words)"
      subquestion="Give us a general overview of your company and your progress so far."
      value={inputs.description}
      onChange={(e) => dispatch({ description: e.target.value })}
    />,
    // industries
    <Stack spacing={2}>
      <Stack sx={{ ...theme.question }}>
        Which categories best apply to your company. Select up to 3.
      </Stack>
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
        }}
      >
        {industries.map((industry) => {
          return (
            <Stack
              sx={{
                mr: "0.5rem",
                mb: "0.5rem",
              }}
            >
              <label
                className={styles.select}
                style={{
                  borderLeft: inputs.categories.includes(industry)
                    ? "4px solid white"
                    : "",
                }}
              >
                <input
                  style={{ display: "none" }}
                  type="checkbox"
                  name="category-checkboxes"
                  value={industry}
                  checked={inputs.categories.includes(industry)}
                  onChange={(e) => {
                    let currentCategories = inputs.categories;
                    let newCategories = [];
                    if (currentCategories.includes(e.target.value)) {
                      newCategories = currentCategories.filter(
                        (i) => i !== e.target.value
                      );
                    } else {
                      newCategories = [...currentCategories, e.target.value];
                    }
                    dispatch({ categories: newCategories.slice(-3) });
                  }}
                />
                {industry}
              </label>
            </Stack>
          );
        })}

        {/* Other Text Box */}
        <Stack
          sx={{
            mr: "0.5rem",
            mb: "0.5rem",
          }}
        >
          <input
            className={styles.select}
            style={{
              borderLeft:
                inputs.categories.filter((i) => i.includes("other:")).length > 0
                  ? "4px solid white"
                  : "",
            }}
            placeholder="Other..."
            name="category-checkboxes"
            value={(() => {
              let other = inputs.categories.filter((i) => i.includes("other:"));
              if (other.length == 0) {
                return "";
              } else {
                return other[0].split(" ").slice(1).join(" ");
              }
            })()}
            onChange={(e) => {
              let currentCategories = inputs.categories;
              let other = inputs.categories.filter((i) => i.includes("other:"));
              if (other.length == 0) {
                dispatch({
                  categories: [
                    ...currentCategories,
                    "other: " + e.target.value,
                  ].slice(-3),
                });
              } else {
                let index = currentCategories.indexOf(other[0]);
                currentCategories[index] = "other: " + e.target.value;
                dispatch({ categories: currentCategories });
              }
            }}
          />
        </Stack>
      </Stack>
    </Stack>,
    <LongAnswer
      question="Tell us about your team and why you are uniquely qualified to
              lead your company. (max 200 words)"
      subquestion="If you’re a solo founder, tell us about your founding story."
      value={inputs.unique_team}
      onChange={(e) => dispatch({ unique_team: e.target.value })}
    />,
    <LongAnswer
      key={3}
      question="Who are your key competitors? How do you differentiate your product/service from them? (max 200 words)"
      subquestion="Feel free to discuss key features and defensibility (IP, network effects, first-mover, etc.) surrounding your venture in your response."
      value={inputs.competitors}
      onChange={(e) => dispatch({ competitors: e.target.value })}
    />,
    <LongAnswer
      key={4}
      question="Quantify and explain the size of your market. (max 150 words)"
      subquestion="What trends and growth patterns support your thesis?"
      value={inputs.market_size}
      onChange={(e) => dispatch({ market_size: e.target.value })}
    />,
    <LongAnswer
      key={5}
      question="Tell us a bit more about your current milestones and traction. (max 150 words)"
      subquestion="Where are you along the process of implementing your idea?"
      value={inputs.traction}
      onChange={(e) => dispatch({ traction: e.target.value })}
    />,
    <LongAnswer
      key={6}
      question="What are your short-term goals? (max 100 words)"
      subquestion="What do you hope to accomplish in the next quarter or two? How will the prize money help you get there?"
      value={inputs.goals}
      onChange={(e) => dispatch({ goals: e.target.value })}
    />,
    <LongAnswer
      key={6}
      question="How will you use the prize money from this competition? (max 100 words)"
      subquestion="How will this money help you achieve your goals?"
      value={inputs.plans}
      onChange={(e) => dispatch({ plans: e.target.value })}
    />,
    <LongAnswer
      key={7}
      question="Optional video pitch (link to YouTube video)"
      subquestion='While a video pitch is not required, there is nothing quite like a founder selling an idea. “Please limit your pitch to 1 minute and make it "Unlisted" or "Public" on YouTube. *Note that the video pitch is required for the ASU Innovation Open.'
      value={inputs.video}
      onChange={(e) => dispatch({ video: e.target.value })}
    />,
    <LongAnswer
      key={12}
      question="How did you hear about TigerLaunch?"
      subquestion=""
      value={inputs.exposure}
      onChange={(e) => dispatch({ exposure: e.target.value })}
    />,
    <Stack spacing={2}>
      <Stack sx={{ ...theme.question }}>
        Ready to submit? You know what to do.
      </Stack>
      <Box
        component={motion.button}
        onClick={() => Push()}
        sx={{
          py: 2,
          px: 4,
          width: "fit-content",
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          color: "white",
          fontWeight: "700",
          border: "1px solid white",
          borderRadius: "0.25rem",
        }}
        whileHover={{
          backgroundColor: "rgba(255, 255, 255, 0.16)",
        }}
      >
        Submit
      </Box>
    </Stack>,
    <>
      <Stack
        sx={{
          ...theme.question,
        }}
      >
        Thank you! Your application has been successfully submitted.
      </Stack>
      ,
      <Stack
        sx={{
          ...theme.subQuestion,
        }}
      >
        TigerLaunch has partnered with ASU Innovation Open, a startup pitch
        competition based in Arizona. Our applications are nearly the same, so
        feel free to visit ASU.io to easily apply to their competition!
      </Stack>
    </>,
  ];

  const changeApplicationPage = (increment) => {
    setApplicationPage((prev) => {
      if (increment > 0) {
        if (inputs[Object.keys(inputs)[prev]].length > 0) {
          setError(false);
          return prev + increment;
        }
        setError(true);
        return prev;
      } else {
        setError(false);
        if (prev > 0) {
          return prev + increment;
        }
        return prev;
      }
    });
  };

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const formSwitch = (page) => {
    if (page === -1) {
      return formFields[formFields.length - 1];
    }
    return formFields[clamp(page, 0, formFields.length)];
  };

  return (
    <Stack
      sx={{
        ...theme.darkBg,
        minHeight: "100vh",
      }}
      alignItems="center"
    >
      <Stack
        sx={{
          ...theme.container,
          backgroundColor: "rgba",
          borderLeft: "1px solid white",
          px: {
            md: 4,
            xs: 2,
          },
          py: {
            md: 8,
            xs: 4,
          },
          minHeight: "100vh",
        }}
        justifyContent={{
          md: "space-between",
          xs: "flex-start",
        }}
        spacing={4}
      >
        <Stack
            sx={{
              ...theme.font.h1,
              ...theme.light,
              fontFamily: "'Rubik'",
              flexShrink: 1,
            }}
            direction="row"
            spacing={{
              md: 4,
              xs: 2,
            }}
            justifyContent="center"
            alignItems="center"
        >
          <Stack
              sx={{
                height: "8rem",
              }}
          >
            <Logo />
          </Stack>
          <Stack
              xs={{
                height: "100%",
              }}
              justifyContent="center"
          >
            Submit your TigerLaunch application
            <Stack
                sx={{
                  ...theme.font.h5,
                  color: theme.lightOrange,
                  mt: 1,
                }}
            >
              Applications are closed! Please check back next year.
            </Stack>
          </Stack>
        </Stack>
        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  spacing={{*/}
        {/*    md: 4,*/}
        {/*    xs: 2,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Stack*/}
        {/*    spacing={{*/}
        {/*      md: 4,*/}
        {/*      xs: 3,*/}
        {/*    }}*/}
        {/*    justifyContent={{*/}
        {/*      md: "center",*/}
        {/*      xs: "flex-start",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {applicationPage === -1 || (*/}
        {/*      <Stack*/}
        {/*        onClick={() => changeApplicationPage(-1)}*/}
        {/*        sx={{*/}
        {/*          cursor: "pointer",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <KeyboardArrowDownOutlined*/}
        {/*          sx={{*/}
        {/*            transform: {*/}
        {/*              md: "scale(2) rotate(180deg)",*/}
        {/*              xs: "scale(1.5) rotate(180deg)",*/}
        {/*            },*/}
        {/*          }}*/}
        {/*          htmlColor="#ffffff"*/}
        {/*        />*/}
        {/*      </Stack>*/}
        {/*    )}*/}
        {/*    {applicationPage === -1 || (*/}
        {/*      <Stack*/}
        {/*        onClick={() => changeApplicationPage(1)}*/}
        {/*        sx={{*/}
        {/*          cursor: "pointer",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <KeyboardArrowDownOutlined*/}
        {/*          sx={{*/}
        {/*            transform: {*/}
        {/*              md: "scale(2)",*/}
        {/*              xs: "scale(1.5)",*/}
        {/*            },*/}
        {/*          }}*/}
        {/*          htmlColor="#ffffff"*/}
        {/*        />*/}
        {/*      </Stack>*/}
        {/*    )}*/}
        {/*  </Stack>*/}
        {/*  <Stack*/}
        {/*    justifyContent="center"*/}
        {/*    sx={{*/}
        {/*      flexGrow: 1,*/}
        {/*    }}*/}
        {/*    spacing={1}*/}
        {/*  >*/}
        {/*    {formSwitch(applicationPage)}empty*/}
        {/*    {error && (*/}
        {/*      <p className={styles.error}>Please fill this in! (or put N/A)</p>*/}
        {/*    )}*/}
        {/*  </Stack>*/}
        {/*</Stack>*/}
        <Stack
          sx={{
            display: {
              md: "none",
              xs: "flex",
            },
            flexGrow: 1,
          }}
        />
        <Stack
          sx={{
            ...theme.font.h4,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Link
            to="/home"
            style={{
              color: "white",
            }}
          >
            &lt; Home
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Apply;
