import axios from "axios";

const getClosestRegional = async (city) => {
  // this is really bad but i dont know how to make this work better without a dedicated backend
  var config = {
    method: "get",
    url: `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${city}&destinations=Houston, TX || Berkeley, CA || NewYork, NY || Paris, France || Singapore&units=metric&key=AIzaSyDcZn_WhntNb08GBNtYkWURT-7OvVfvXno`,
  };
  try {
    let res = await axios(config);
    let data = res.data.rows[0].elements;
    console.log(res.data);
    let min = Infinity;
    let placeIndex = -1;

    data.forEach((place, index) => {
      console.log(place);
      if (place.status === "OK") {
        if (place.distance.value < min) {
          console.log(place);
          placeIndex = index;
          min = place.distance.value;
        }
      }
    });
    if (placeIndex === -1) {
      return "undetermined";
    }
    return res.data.destination_addresses[placeIndex];
  } catch (error) {
    return "undetermined";
  }
};

export default getClosestRegional;
