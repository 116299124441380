import React from "react";
import square from "../img/logo-square.png";
import {useNavigate} from "react-router-dom";

export default function Logo(props) {

    const nav = useNavigate();

    return (
        <img onClick={() => nav("/home")} tabIndex={0} style={{
            height: "100%",
            width: "auto",
            cursor: "pointer",
        }} src={square} alt="TigerLaunch logo" {...props} />
    )
}