// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithRedirect, signOut } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKEj4Ir92y2i22GPpPRyR80RuwLv1iKFE",
  authDomain: "tigerlaunch-website.firebaseapp.com",
  projectId: "tigerlaunch-website",
  storageBucket: "tigerlaunch-website.appspot.com",
  messagingSenderId: "454427372510",
  appId: "1:454427372510:web:1c0883362fefb91acd8b4f",
  measurementId: "G-J0RLTKZQCZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const logOut = () => {
  signOut(auth)
    .then(() => {
      console.log("signed out");
    })
    .catch((error) => {
      console.log(error);
    });
};

// console.log(auth);

export { db, auth, provider, logOut };
