import {createTheme} from "@mui/material";

const theme = createTheme({
    orange: "#ff5200",
    mediumDarkOrange: "#fe6600",
    mediumOrange: "#fd7c00",
    mediumLightOrange: "#fa8f0d",
    lightOrange: "#f99d1c",
    blackColor: "#1b1b1b",
    darkColor: "#1e1e30",
    orangeBg: {
        backgroundColor: "#ff5200",
    },
    mediumOrangeBg: {
        backgroundColor: "#fd7c00",
    },
    lightOrangeBg: {
        backgroundColor: "#f99d1c",
    },
    dark: {
        color: "#1e1e30",
    },
    light: {
        color: "#f3f2f1",
    },
    blackBg: {
        backgroundColor: "#1b1b1b",
    },
    darkBg: {
        backgroundColor: "#1e1e30",
    },
    lightBg: {
        backgroundColor: "#f3f2f1",
    },
    font: {
        nav: {
            fontSize: {
                md: "1rem",
                sm: "0.75rem",
                xs: "0.50rem",
            }
        },
        h0: {
            fontSize: {
                lg: "4rem",
                md: "3.5rem",
                sm: "3rem",
                xs: "2.5rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 700",
        },
        h1: {
            fontSize: {
                lg: "3.5rem",
                md: "3rem",
                sm: "2.5rem",
                xs: "2rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 700",
        },
        h2: {
            fontSize: {
                md: "2.5rem",
                xs: "2rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 700",
        },
        h3: {
            fontSize: {
                md: "2rem",
                xs: "1.5rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 600",
        },
        h4: {
            fontSize: {
                md: "1.5rem",
                xs: "1.25rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 500",
        },
        h5: {
            fontSize: {
                md: "1.25rem",
                xs: "1rem",
            },
            fontFamily: "'Rubik', sans-serif",
            fontVariationSettings: "'wght' 400",
        },
        h6: {
            fontSize: {
                md: "0.5rem",
            }
        },
    },
    smallTitle: {
        fontWeight: 500,
        fontSize: {
            md: "1rem",
        },
        borderLeft: "4px solid black",
        paddingLeft: "0.5rem",
    },
    heroText: {
        fontSize: {
            xs: "0.75rem",
            md: "1.25rem",
        }
    },
    threeSectionHeader: {
        fontSize: "1.5rem",
        padding: "1rem",
        fontVariationSettings: "'wght' 500",
    },
    container: {
        width: {
            lg: "70rem",
            md: "54rem",
            xs: "90%",
        }
    },
    question: {
        fontSize: {
            lg: "2rem",
            md: "1.5rem",
            xs: "1.25rem",
        },
        color: "white",
        fontFamily: "'Karla', sans-serif",
        fontWeight: 700,
    },
    subQuestion: {
        fontSize: {
            lg: "1.25rem",
            md: "1rem",
            xs: "1rem",
        },
        color: "white",
        fontFamily: "'Karla', sans-serif",
        fontWeight: 500,
    },
    subsubQuestion: {
        fontSize: {
            lg: "1.25rem",
            md: "1rem",
            xs: "1rem",
        },
        color: "white",
        fontFamily: "'Karla', sans-serif",
    },
    badge: {
        fontSize: {
            md: "1rem",
            xs: "0.75rem",
        },
        py: 1,
        px: 2,
        borderRadius: "1.5rem",
        fontFamily: "'Rubik', sans-serif",
        fontVariationSettings: "'wght' 700",
    },
    sortButton: {
        fontSize: {
            md: "1rem",
            xs: "0.75rem",
        }
    },
    sideCard: {
        fontSize: {
            lg: "3rem",
            md: "2.5rem",
            xs: "2rem",
        },
        lineHeight: {
            lg: "2.25rem",
            md: "1.875rem",
            xs: "1.5rem",
        }
    }
})

export default theme;