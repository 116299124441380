import React, {useState, useEffect} from "react";
import {db, auth, provider} from "../firebase.js";
import {signInWithRedirect, signInWithEmailAndPassword} from "firebase/auth";
import {collection, doc, updateDoc, onSnapshot} from "firebase/firestore";
import {Box, Stack, useTheme} from "@mui/material";
import Logo from "../generic/Logo";
import {motion} from "framer-motion";
import BigButton from "../generic/BigButton";
import {useNavigate} from "react-router-dom";
import {Google} from "@mui/icons-material";

function Login() {
    const appsRef = collection(db, "apps");
    const theme = useTheme();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    let [error, setError] = useState(false);

    return (
        <Stack
            sx={{
                ...theme.darkBg,
                height: "100vh",
            }}
            alignItems="center"
        >
            <Stack
                sx={{
                    ...theme.container,
                    height: "100vh",
                    borderLeft: "1px solid white",
                }}
            >
                <Stack
                    sx={{
                        ...theme.font.h1,
                        ...theme.light,
                        fontFamily: "'Rubik'",
                        flexShrink: 1,
                        py: 8,
                        px: 4,
                    }}
                    direction="row"
                    spacing={{
                        md: 4,
                        xs: 2,
                    }}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Stack
                        sx={{
                            height: "8rem",
                        }}
                    >
                        <Logo/>
                    </Stack>
                    <Stack
                        sx={{
                            height: "100%",
                            py: 2,
                        }}
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Stack>Dashboard Login</Stack>
                        <Stack
                            sx={{
                                ...theme.font.h4,
                                ...theme.light,
                            }}
                        >
                            For judges and TigerLaunch personnel.
                        </Stack>
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        px: 4,
                        width: "100%",
                    }}
                    spacing={4}
                >
                    <Stack spacing={1} width={"100%"}>
                        <Stack
                            sx={{
                                ...theme.font.h4,
                                ...theme.light,
                            }}
                        >
                            Email:
                        </Stack>
                        <input
                            type="text"
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Stack
                            sx={{
                                ...theme.font.h4,
                                ...theme.light,
                            }}
                        >
                            Password:
                        </Stack>
                        <input
                            type="password"
                            placeholder="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Stack>
                    {error && (
                        <Stack
                            sx={{
                                ...theme.font.h5,
                            }}
                            style={{color: "red"}}
                            component={motion.div}
                            layout
                        >
                            ERROR: Invalid credentials. Did you mistype something?
                        </Stack>
                    )}
                    <Stack spacing={4} direction="row" component={motion.div} layout>
                        <BigButton
                            sx={{
                                ...theme.font.h4,
                            }}
                            text={<Stack px={1}>
                                Login
                            </Stack>}
                            onClick={() =>
                                signInWithEmailAndPassword(auth, email, password)
                                    .then((userCredential) => {
                                        // Signed in
                                        const user = userCredential.user;
                                        navigate("/dashboard");
                                    })
                                    .catch((error) => {
                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        setError(true);
                                    })
                            }
                        />
                        <BigButton
                            sx={{
                                ...theme.font.h4,
                            }}
                            text={
                                <Stack direction="row" alignItems="center" spacing={1.5}>
                                    <Google sx={{
                                        height: "100%",
                                        scale: "1.25",
                                    }} />
                                    <Stack>
                                        Continue with Google
                                    </Stack>
                                </Stack>
                            }
                            onClick={() => signInWithRedirect(auth, provider)}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default Login;
