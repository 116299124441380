import React, {useEffect, useRef, useState} from "react";
import "../scss/core.scss"
import "../scss/generic.scss"
import {range} from "../utils.js";
import Particle from "./Particle";

// add outside any div for particles in that div!!

function ParticleWrapper(props) {

    const { particleCount } = props;

    return <div className="particle-wrapper w-100 h-100">
        {
            range(0, particleCount ?? 100, 1).map((v, i) => {
                return <Particle key={i} />
            })
        }
        <div className={props.className ?? ""} style={{
            zIndex: 1,
        }}>
            {props.children}
        </div>
    </div>
}

export default ParticleWrapper;