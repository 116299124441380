import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { signInWithRedirect } from "firebase/auth";
import { collection, doc, updateDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, auth, logOut } from "../firebase.js";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    logOut();
    navigate("/home");
  }, []);

  return <></>;
}

export default Logout;
