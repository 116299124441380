import React, { useEffect, useState } from "react";
import "../scss/core.scss";
import Nav from "./Nav";
import Footer from "./Footer";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import theme from "../theme.js";
import { ThemeProvider } from "@mui/material";
import Disclaimer from "./Disclaimer";
import About from "../pages/About";
import Partners from "../pages/Partners";
import Companies from "../pages/Companies";
import Team from "../pages/Team";
import Finals from "../pages/Finals";

export const LayoutContext = React.createContext({
  scrollY: 0,
});

export default function Layout(props) {
  const [scrollY, setScrollY] = useState(0);

  const scrollListener = (e) => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        scrollY: scrollY,
      }}
    >
      <Nav />
      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path={"finals"} element={<Finals />} />
        <Route path={"about"} element={<About />} />
        <Route path={"partners"} element={<Partners />} />
        <Route path={"companies"} element={<Companies />} />
        <Route path={"team"} element={<Team />} />
      </Routes>
      <Footer />
      {/* <Disclaimer>
                The new TigerLaunch website is currently under construction. Please bear with us!
                <br/>
                Click this box to hide.
            </Disclaimer> */}
    </LayoutContext.Provider>
  );
}
